import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const ArrowBackIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.14004 10.9998L12 7.41666L10.633 5.97387L5.10708 10.9998H5V11.0971L4 12.0067L5 12.9329V12.9998H5.07216L10.4777 18.0067L11.8786 16.5044L8.12029 12.9998L20 12.9998V10.9998L8.14004 10.9998Z"
        fill="currentColor"
      />
    </svg>
  )
})
ArrowBackIcon.displayName = 'ArrowBackIcon'
export default ArrowBackIcon
