import React from 'react'
import clsx from 'clsx'
import MinusIcon from '../../Icons/MinusIcon'
import PlusIcon from '../../Icons/PlusIcon'

interface Props {
  title?: string
  description?: string
  value: number
  downDisabled: boolean
  upDisabled: boolean
  ageRange?: string
  e2eAttribute: string
  onValueChange: (value: number) => void
}

const Selector: React.FC<Props> = ({ title, value, description, downDisabled, upDisabled, e2eAttribute, onValueChange }) => {
  const [emptyCounterValue, setEmptyCounterValue] = React.useState<boolean>(false)

  return (
    <>
      <div className="ts-mr-auto">
        <div className="ts-font-medium ts-text-cvi-brand-brand900">{title}</div>
        <div className="ts-font-normal ts-text-sm ts-text-cvi-grey-grey700">{description}</div>
      </div>
      <div className="ts-inline-flex">
        <div className="ts-flex ts-flex-row ts-items-center">
          <button
            data-e2e={`${e2eAttribute}-down`}
            disabled={downDisabled}
            className={clsx(
              'travel-search-button ts-text-white ts-rounded-full ts-inline-flex ts-h-dfds-l ts-w-dfds-l ts-items-center ts-justify-center',
              downDisabled ? 'ts-bg-cvi-grey-grey100' : 'ts-bg-cvi-brand-brand900'
            )}
            onClick={() => {
              onValueChange(value - 1)
            }}
          >
            <MinusIcon size="md" className={downDisabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} />
          </button>
          <input
            data-e2e={`${e2eAttribute}-input`}
            className="travel-search-input lg:ts-mx-dfds-xs ts-h-[40px] ts-border-none ts-w-[40px] ts-appearance-none ts-border ts-border-dfds-text-dark-primary ts-bg-transparent ts-text-center ts-text-cvi-brand-brand900 ts-font-medium tabletDown:ts-mx-dfds-xs"
            type="number"
            value={emptyCounterValue ? '' : value}
            onKeyDown={(e: any) => e?.key === 'Backspace' && setEmptyCounterValue(true)}
            onFocus={() => setEmptyCounterValue(true)}
            onBlur={() => setEmptyCounterValue(false)}
            onChange={(e: any) => {
              if (!isNaN(e.target.value)) {
                onValueChange(e.target.value)
                setEmptyCounterValue(false)
              }
            }}
          />
          <button
            data-e2e={`${e2eAttribute}-up`}
            disabled={upDisabled}
            className={clsx(
              'travel-search-button ts-text-white ts-rounded-full ts-inline-flex ts-h-dfds-l ts-w-dfds-l ts-items-center ts-justify-center focus:ts-border-cvi-brand-brand500',
              upDisabled ? 'ts-bg-cvi-grey-grey100' : ' ts-bg-cvi-brand-brand900'
            )}
            onClick={() => {
              onValueChange(value + 1)
            }}
          >
            <PlusIcon size="md" className={upDisabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} />
          </button>
        </div>
      </div>
    </>
  )
}

export default Selector
