import TravelSearch from './Components/TravelSearch'
import i18n from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { fetcher, getBaseApi, getLocaleFromUrlOrQuery } from './Utils/common'
import { useState } from 'react'
import { TravelSearchSkeleton } from './Components/Shared/TravelSearchSkeleton'
import clsx from 'clsx'
import { TravelSearchOfferSkeleton } from './Components/Shared/TravelSearchOfferSkeleton'
import useSWR from 'swr'

const AppWithContent = ({ hasOffer }: { hasOffer: boolean | undefined }) => {
  const [ready, setReady] = useState<boolean>(false)

  const locale = getLocaleFromUrlOrQuery()
  const baseApi = getBaseApi()

  const _i18Instance = i18n.createInstance()
  _i18Instance.use(initReactI18next).init({
    debug: false,
    lng: locale,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {},
  })

  const { data } = useSWR(`${baseApi}/content?locale=${locale}`, fetcher, {
    onErrorRetry: (err, _key, _config, revalidate, { retryCount }) => {
      if (err.status === 404) return
      if (retryCount >= 5) return
      setTimeout(() => revalidate({ retryCount }), 1000)
    },
  })
  if (data) _i18Instance.addResourceBundle(locale.substring(0, 2), 'translation', data.content)

  return (
    <div id="travel-search">
      <div className={clsx(ready ? 'ts-hidden' : 'ts-block')}>{hasOffer ? <TravelSearchOfferSkeleton /> : <TravelSearchSkeleton />}</div>
      {data && (
        <I18nextProvider i18n={_i18Instance}>
          <div className={clsx(ready ? 'ts-block' : 'ts-hidden')}>
            <TravelSearch
              onReady={() => {
                setReady(true)
              }}
            />
          </div>
        </I18nextProvider>
      )}
    </div>
  )
}

export default AppWithContent
