import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const PassengerIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2C14 3.10457 13.1046 4 12 4C10.8954 4 10 3.10457 10 2C10 0.89543 10.8954 0 12 0C13.1046 0 14 0.89543 14 2ZM14.5001 5L18.1801 10.0815C18.6871 10.8055 18.5516 11.7635 17.8276 12.2705L17.5001 12.5L14.5001 8.391V24H12.5001V15H11.5001V24H9.50006V8.391L6.50006 12.5L6.17256 12.2705C5.44856 11.7635 5.31306 10.805 5.82006 10.0815L9.50006 5H14.5001Z"
        fill="currentColor"
      />
    </svg>
  )
})
PassengerIcon.displayName = 'PassengerIcon'
export default PassengerIcon
