import React, { createContext, useContext, useReducer } from 'react'
import { TravelSearchActions, TravelSearchState } from './travelSearchTypes'

const initialState: TravelSearchState = {
  selectedDates: { outboundDate: undefined, homeboundDate: undefined },
  selectedCurrency: 'EUR',
  multipleVehiclesChecked: false,
  multipleVehiclesSupported: false,
  petsOnlyAllowedInVehicle: false,
  disableOneWay: false,
  isOneWay: false,
}

function reducer(state: TravelSearchState, action: TravelSearchActions): TravelSearchState {
  switch (action.type) {
    case 'SET_ROUTE':
      return { ...state, selectedRoute: action.route }
    case 'SET_VEHICLE':
      return { ...state, selectedVehicle: action.vehicle }
    case 'SET_PASSENGERS':
      return { ...state, selectedPassengers: action.passengers }
    case 'SET_DATES':
      return { ...state, selectedDates: action.dates }
    case 'SET_CURRENCY':
      return { ...state, selectedCurrency: action.currency }
    case 'SET_MULTIPLE_VEHICLES_CHECKED':
      return { ...state, multipleVehiclesChecked: action.checked }
    case 'SET_MULTIPLE_VEHICLES_SUPPORTED':
      return { ...state, multipleVehiclesSupported: action.supported }
    case 'SET_PETS_ONLY_ALLOWED_IN_VEHICLE':
      return { ...state, petsOnlyAllowedInVehicle: action.allowed }
    case 'SET_DISABLE_ONE_WAY':
      return { ...state, disableOneWay: action.disabled }
    case 'SET_TRAILER_NO_LONGER_AVAILABLE':
      return { ...state, selectedTrailerNoLongerAvailable: action.trailer }
    case 'SET_ONEWAY':
      return { ...state, isOneWay: action.isOneWay }

    // Set API data
    case 'SET_ROUTES_API_DATA':
      return { ...state, routesApiData: action.payload }
    default:
      return state
  }
}

const TravelSearchContext = createContext<{
  state: TravelSearchState
  dispatch: React.Dispatch<TravelSearchActions>
} | null>(null)

export const TravelSearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <TravelSearchContext.Provider value={{ state, dispatch }}>{children}</TravelSearchContext.Provider>
}

export const useTravelSearchContext = () => {
  const context = useContext(TravelSearchContext)
  if (!context) {
    throw new Error('useTravelSearchContext must be used within a TravelSearchProvider')
  }
  return context
}
