import { RefObject, useEffect, useRef } from 'react'
import OverlayDateFooter from './OverlayDateFooter'
import { toShortDateString } from '../../Utils/date'
import { useTranslation } from 'react-i18next'
import { useWindowWidth2 } from '../../Hooks/useWindowWidth2'
import { useThreeDots } from '../../Hooks/useThreeDots'
import clsx from 'clsx'
import CalendarIcon from '../../Icons/CalendarIcon'
import ArrowForwardIcon from '../../Icons/ArrowForwardIcon'
import ChevronUpIcon from '../../Icons/ChevronUpIcon'
import ChevronDownIcon from '../../Icons/ChevronDownIcon'
import CloseIcon from '../../Icons/CloseIcon'

interface Props {
  showFooter: boolean
  isOneway: boolean
  isOpen: boolean
  fieldName: string
  returnFocused: boolean
  selectedOutboundDate: Date | undefined
  selectedHomeboundDate?: Date | undefined
  isLoading: boolean
  children: React.ReactNode
  dataAttrList: { name: string; value?: string }[]
  containerRef: RefObject<HTMLDivElement>
  disabled?: boolean
  onClick: (clickedOutside: boolean, clickedReturnField?: boolean) => void
  onClose: () => void
}

const DateField: React.FC<Props> = ({
  isOneway,
  isOpen,
  fieldName,
  selectedOutboundDate,
  selectedHomeboundDate,
  isLoading,
  children,
  dataAttrList,
  disabled,
  onClick,
  onClose,
}) => {
  const { t } = useTranslation()

  const threeDots = useThreeDots()
  const { isMobile, isTablet } = useWindowWidth2()
  const ref = useRef<HTMLDivElement>(null)
  const wasClicked = useRef(false)

  let dataAttrProps = {} as ComponentProps
  dataAttrList.map((dataAttr) => {
    const key = `data-e2e-${dataAttr?.name}`
    const dataAttrKey = key as keyof ComponentProps
    dataAttrProps[dataAttrKey] = dataAttr.value?.toString() ?? ''
  })
  const keyLoading = `data-e2e-${fieldName}-loading`
  const dataAttrKeyLoading = keyLoading as keyof ComponentProps
  dataAttrProps[dataAttrKeyLoading] = isLoading.toString()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!(event?.target?.name === 'day' && event?.target?.type === 'button')) {
          // This is a hack to prevent the datepicker from closing when clicking on a day in the calendar on offers because a rerender happens when homebound dates are loaded
          onClick(true)
        }
      }
    }

    if (isOpen && !isMobile && !isTablet && ref) addEventListener('click', handleClickOutside)
    if (!isOpen && ref) window.removeEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [isOpen, ref])

  return (
    <>
      <div
        ref={ref}
        className="ts-relative ts-h-full ts-flex ts-flex-row ts-gap-dfds-xs lg:ts-gap-0 h-[58px]"
        id={`${fieldName}-field-container`}
        {...dataAttrProps}
      >
        <div
          onMouseDown={() => {
            wasClicked.current = true
          }}
          className={clsx(
            'ts-bg-white ts-w-full ts-h-full ts-flex ts-flex-row  ts-gap-dfds-xs ts-items-center ts-px-dfds-xs hover:ts-bg-cvi-states-selected ts-transition ts-duration-200',
            disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
          )}
          onClick={() => {
            if (!isOpen && !isLoading && !disabled) {
              onClick(false)
            } else onClose()
            wasClicked.current = false
          }}
          tabIndex={0}
          onFocus={() => {
            if (wasClicked.current) return
            if (!isOpen && !isLoading && !disabled) {
              onClick(false)
            }
          }}
        >
          <div className="ts-flex ts-justify-between ts-items-center ts-w-full ">
            <div
              className={clsx(
                'ts-pr-2',
                disabled ? 'ts-cursor-not-allowed ts-text-cvi-grey-grey300' : 'ts-text-cvi-brand-brand900 ts-cursor-pointer'
              )}
            >
              <CalendarIcon size="md" />
            </div>
            <div className="ts-flex ts-items-center ts-flex-1 ts-w-full ">
              <div className="ts-flex ts-items-center ts-gap-2 ts-justify-start ts-flex-1 md:ts-flex-initial ">
                <div className="ts-truncate ts-flex ts-flex-col ts-gap-1 ts-justify-center ts-flex-1 md:ts-flex-initial ">
                  <label
                    className={clsx(
                      'ts-select-none ts-dfds-label-small ',
                      disabled ? 'ts-cursor-not-allowed ts-text-cvi-grey-grey300' : 'ts-cursor-pointer ts-text-cvi-brand-brand900'
                    )}
                  >
                    {isOneway ? t('DEPARTURE-DATE-LABEL') : t('OUTBOUND')}
                  </label>
                  <label
                    data-e2e="outbound-date-input"
                    id={`${fieldName}-input`}
                    className={clsx(
                      'ts-flex-none ts-border-none ts-font-medium ts-text-cvi-brand-brand900 ts-leading-none ts-select-none focus:ts-outline-none ts-truncate',
                      disabled ? 'ts-text-cvi-grey-grey300 ts-cursor-not-allowed' : 'ts-cursor-pointer'
                    )}
                  >
                    {isLoading ? threeDots : toShortDateString(selectedOutboundDate)}
                  </label>
                </div>
              </div>

              {!isOneway && (
                <>
                  <div className="ts-text-dfds-text-primary-disabled ts-px-6">
                    <ArrowForwardIcon size="lg" />
                  </div>
                  <div className={clsx('ts-truncate ts-flex ts-flex-col ts-gap-1 ts-justify-center  ts-flex-1 md:ts-flex-initial ')}>
                    <label
                      className={clsx(
                        'ts-select-none ts-dfds-label-small ',
                        disabled ? 'ts-cursor-not-allowed ts-text-cvi-grey-grey300' : 'ts-cursor-pointer ts-text-cvi-brand-brand900'
                      )}
                    >
                      {t('INBOUND')}
                    </label>
                    <label
                      data-e2e="homebound-date-input"
                      id={`${fieldName}-input`}
                      className={clsx(
                        'ts-flex-none ts-border-none ts-font-medium ts-text-cvi-brand-brand900 ts-leading-none ts-select-none focus:ts-outline-none ts-truncate',
                        disabled || isOneway ? 'ts-text-cvi-grey-grey300 ts-cursor-not-allowed' : 'ts-cursor-pointer'
                      )}
                    >
                      {isLoading && !isOneway ? threeDots : toShortDateString(selectedHomeboundDate)}
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className="ts-ml-auto ts-pointer-events-none">{isOpen ? <ChevronUpIcon size="md" /> : <ChevronDownIcon size="md" />}</div>
          </div>
        </div>
        <div
          className={clsx(
            `ts-z-modal ts-bg-white lg:ts-absolute lg:ts-top-[58px] lg:ts-mt-dfds-xs lg:ts-bg-white lg:ts-shadow-md lg:ts-drop-shadow-xl tabletDown:ts-fixed tabletDown:ts-bottom-0 tabletDown:ts-left-0 tabletDown:ts-right-0 tabletDown:ts-top-0 tabletDown:ts-w-full`,
            !isOpen && 'ts-invisible',
            isOpen && 'ts-left-[-250px]'
          )}
        >
          <div className="ts-flex ts-h-full ts-flex-col lg:ts-p-dfds-s">
            <div id="header-content" className="ts-flex ts-h-[64px] ts-min-h-[64px] ts-flex-row ts-items-center ts-pl-dfds-s lg:ts-hidden">
              <label className="ts-max-w-[350px] ts-text-xl ts-font-medium ts-text-cvi-brand-brand900">{t('SELECT-DATE')}</label>
              <div
                className={`ts-fill-bg-500 ts-ml-auto ts-flex ts-h-full ts-w-12 ${
                  isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
                } ts-items-center ts-justify-center ts-text-dfds-text-dark-primary hover:ts-text-cvi-brand-brand500`}
                onClick={() => {
                  if (!disabled || isOneway) {
                    onClick(true)
                  }
                }}
              >
                <CloseIcon size="lg" />
              </div>
            </div>
            <div className="ts-border-b ts-border-solid ts-border-0 ts-border-gray-300 lg:ts-hidden"></div>
            <div id="main-content" className="lg:ts-bg-white tabletDown:ts-h-[calc(100%-128px)] tabletDown:ts-overflow-y-scroll">
              {children}
            </div>
            {selectedOutboundDate && (
              <OverlayDateFooter
                isLoading={isLoading}
                isOneway={isOneway}
                outboundDate={selectedOutboundDate}
                homeboundDate={selectedHomeboundDate}
                onClick={() => onClick(false)}
              >
                <div className="ts-flex ts-flex-row ts-items-center"></div>
              </OverlayDateFooter>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DateField

interface ComponentProps {
  dataAttrName: string
  dataAttrValue: string
}
