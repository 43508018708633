import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const CaravanIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        d="M5 16C3.61929 16 2.5 17.1193 2.5 18.5C2.5 19.8807 3.61929 21 5 21C6.38071 21 7.5 19.8807 7.5 18.5C7.5 17.1193 6.38071 16 5 16ZM5 19.5C4.44763 19.5 4 19.0524 4 18.5C4 17.9476 4.44763 17.5 5 17.5C5.55237 17.5 6 17.9476 6 18.5C6 19.0524 5.55237 19.5 5 19.5Z"
        fill="currentColor"
      />
      <path
        d="M22 17V9.89999L16 4.5H0V7.5H3V12H0V18.5H1.5C1.5 16.567 3.06702 15 5 15C6.93298 15 8.5 16.567 8.5 18.5H24V17H22ZM14.5 17H10V7.5H14.5V17Z"
        fill="currentColor"
      />
    </svg>
  )
})
CaravanIcon.displayName = 'CaravanIcon'
export default CaravanIcon
