import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const CarIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12V13H21.5V14.5H24V17.5C24 17.8978 23.842 18.2794 23.5607 18.5607C23.2794 18.842 22.8978 19 22.5 19H18.475C18.223 16.477 16.0885 14.5 13.5 14.5C10.9115 14.5 8.777 16.477 8.525 19H0V14.0085H2.5V13.0085H0V10.5H10.5L7.5 6H0V4.5H8.5L12.5 10.5L24 12ZM10.6716 16.6716C11.4217 15.9214 12.4391 15.5 13.5 15.5C14.5609 15.5 15.5783 15.9214 16.3284 16.6716C17.0786 17.4217 17.5 18.4391 17.5 19.5C17.5 20.5609 17.0786 21.5783 16.3284 22.3284C15.5783 23.0786 14.5609 23.5 13.5 23.5C12.4391 23.5 11.4217 23.0786 10.6716 22.3284C9.92143 21.5783 9.5 20.5609 9.5 19.5C9.5 18.4391 9.92143 17.4217 10.6716 16.6716ZM12.0858 20.9142C12.4609 21.2893 12.9696 21.5 13.5 21.5C14.0304 21.5 14.5391 21.2893 14.9142 20.9142C15.2893 20.5391 15.5 20.0304 15.5 19.5C15.5 18.9696 15.2893 18.4609 14.9142 18.0858C14.5391 17.7107 14.0304 17.5 13.5 17.5C12.9696 17.5 12.4609 17.7107 12.0858 18.0858C11.7107 18.4609 11.5 18.9696 11.5 19.5C11.5 20.0304 11.7107 20.5391 12.0858 20.9142Z"
        fill="currentColor"
      />
    </svg>
  )
})
CarIcon.displayName = 'CarIcon'
export default CarIcon
