import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const ChevronRightIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8 16.59L12.9447 12L8 7.41L9.52227 6L16 12L9.52227 18L8 16.59Z" fill="currentColor" />
    </svg>
  )
})
ChevronRightIcon.displayName = 'ChevronRightIcon'
export default ChevronRightIcon
