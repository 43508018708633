import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const ChevronDownIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.41 8L12 12.9447L16.59 8L18 9.52227L12 16L6 9.52227L7.41 8Z" fill="currentColor" />
    </svg>
  )
})
ChevronDownIcon.displayName = 'ChevronDownIcon'
export default ChevronDownIcon
