import { useCallback, useEffect, useRef, useState } from 'react'
import SelectField from './Shared/SelectField'
import Vehicles from './Vehicles/Vehicles'
import Passengers from './Passengers/Passengers'
import DateField from './Date/DateField'
import { getAppMinibookId, getDatepickerLocale, toShortDateString } from '../Utils'
import OnewayReturn from './OnewayReturn/OnewayReturn'
import { useTranslation } from 'react-i18next'
import { useVehicles, useTravelDates, useKeepScrollPosition, AvailableTrailer, UseVehiclesResult } from '../Hooks'
import { LoadDataFromParams } from '../Utils/url-helpers'
import StickyButton from './StickyButton'
import { useWindowWidth2 } from '../Hooks/useWindowWidth2'
import { format } from 'date-fns'
import {
  TrackDates,
  TrackMultipleVehicles,
  TrackOneWay,
  TrackPassengers,
  TrackRoute,
  TrackVehicle,
  TrackingCommand,
  TravelSearchTrackingTypes,
  getTrackingSession,
} from '../Utils/tracking'
import MultiOffer from './MultiOffer/MultiOffer'
import { useTravelSearchConfig } from '../Hooks/useTravelSearchConfig'
import { isFrsRoute, mapToCurrencyToContentfulResource } from '../Utils/frs-helpers'
import Currency from './Currency/Currency'
import { getSession, useBookingUrl } from '../Hooks/useBookingUrl'
import React from 'react'
import { VehicleIcon } from './Vehicles/VehicleIcon'
import clsx from 'clsx'
import { DatePicker, DateRangePicker, Skeleton, Spinner } from '@dfds-pax/ui'
import {
  getMultipleVehicleSelectedCount,
  isMultipleVehclesSupportedWithRoute,
  isMultipleVehclesSupportedWithVehicleType,
} from '../Utils/vehicles'
import { motion } from 'framer-motion'
import { isPetsOnlyAllowedInVehicle } from '../Utils/passengers'
import debounce from 'lodash.debounce'
import RoutesWithSearch from './Routes/Routes'
import { BackToMultiOffer } from './MultiOffer/BackToMultiOffer'
import { loggerAsync, LoggingInterfaces } from '@dfds-pax/logging-provider'
import { v4 as uuidv4 } from 'uuid'
import { getClientEnvironment } from '@dfds-pax/common'
import RoroFerryIcon from '../Icons/RoroFerryIcon'
import FerryReturnIcon from '../Icons/FerryReturnIcon'
import PassengerIcon from '../Icons/PassengerIcon'
import CurrencyIcon from '../Icons/CurrencyIcon'
import { useTravelSearchContext } from '../context/TravelSearchProvider'
import { useLoadRoutesIntoContext } from '../Hooks/routes/useLoadRoutesIntoContext'

interface Props {
  onReady: () => void
}

const TravelSearch: React.FC<Props> = ({ onReady }) => {
  const stickyTravelSearchRef = useRef<HTMLInputElement>(null)
  const height: number = stickyTravelSearchRef.current?.getBoundingClientRect().height ?? 0
  const {
    state: {
      selectedRoute,
      selectedVehicle,
      selectedPassengers,
      selectedCurrency,
      selectedDates,
      multipleVehiclesChecked,
      multipleVehiclesSupported,
      disableOneWay,
      petsOnlyAllowedInVehicle,
      routesApiData,
      isOneWay,
    },
    dispatch,
  } = useTravelSearchContext()

  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [initialLoad, setInitialLoad] = useState<boolean>(false)
  const { queryData } = LoadDataFromParams()
  const [activeSelectField, setActiveSelectField] = useState<ActiveSelectField>(ActiveSelectField.NONE)
  const [returnFocused, setReturnFocused] = useState<boolean>(false)
  const travelSearchRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement)
  const selectorContainer = useRef<HTMLDivElement>(null as unknown as HTMLDivElement)
  const [inView, setInView] = useState<boolean>(false)
  const [routeSelectorScrollable, setRouteSelectorScrollable] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>()
  const selectedOffercode = ''
  // const [selectedOffercode, setSelectedOffercode] = useState<string>(queryData.offerCode ?? '')
  const [_, setShowReturnChanged] = useState<boolean>(false)
  const [showMultiOffer, setShowMultiOffer] = useState<boolean | undefined>()
  const [showMultiOfferModal, setShowMultiOfferModal] = useState<boolean>(false)
  const [minibookId, setMinibookId] = useState<string>(queryData.minibookId ?? getAppMinibookId(window.location.host) ?? '')
  const [selectedTrailerNoLongerAvailable, setSelectedTrailerNoLongerAvailable] = useState<AvailableTrailer | undefined>(undefined)

  const { isMobile, isTablet } = useWindowWidth2()

  const { t } = useTranslation()

  const travelSearchConfigResult = useTravelSearchConfig()

  const vehicleTypesResult = useVehicles(selectedVehicle, selectedPassengers, multipleVehiclesChecked, selectedOffercode)
  const travelDatesResult = useTravelDates(vehicleTypesResult, selectedCurrency, selectedOffercode)
  useKeepScrollPosition(isMobile || isTablet, activeSelectField !== ActiveSelectField.NONE)

  useLoadRoutesIntoContext(minibookId)

  useEffect(() => {
    dispatch({
      type: 'SET_DATES',
      dates: {
        outboundDate: queryData.outboundDate ? new Date(queryData.outboundDate) : undefined,
        homeboundDate: queryData.homeboundDate ? new Date(queryData.homeboundDate) : undefined,
      },
    })
    dispatch({
      type: 'SET_ONEWAY',
      isOneWay: queryData.oneWay ?? false,
    })
  }, [])

  useEffect(() => {
    if (
      !travelSearchConfigResult?.isMultiOffer &&
      typeof travelSearchConfigResult?.availableMinibooks === 'string' &&
      !queryData.minibookId
    )
      setMinibookId(getAppMinibookId(window.location.host) ?? travelSearchConfigResult.availableMinibooks)
    setShowMultiOffer(travelSearchConfigResult?.isMultiOffer)
  }, [travelSearchConfigResult?.isMultiOffer])

  useEffect(() => {
    const onScroll = () => {
      const rect = travelSearchRef.current?.getBoundingClientRect()

      if (rect) {
        if (!routeSelectorScrollable && rect.bottom < -220) {
          setRouteSelectorScrollable(true)
          if (activeSelectField === ActiveSelectField.ROUTE) {
            setActiveSelectField(ActiveSelectField.NONE)
          }
        } else if (rect.bottom >= 0) {
          setRouteSelectorScrollable(false)
        }

        setInView(rect.bottom < -220)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [routeSelectorScrollable, activeSelectField])

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === 'Escape') {
        setActiveSelectField(ActiveSelectField.NONE)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  //tracking
  useEffect(() => {
    if (
      travelSearchConfigResult.isLoading === false &&
      travelDatesResult.isLoading === false &&
      !!routesApiData &&
      vehicleTypesResult.isLoading === false
    ) {
      TrackingCommand(
        TravelSearchTrackingTypes.INITIALIZE,
        selectedRoute,
        {
          outboundDate: travelDatesResult.outboundTravelDates?.defaultDate
            ? new Date(travelDatesResult.outboundTravelDates?.defaultDate)
            : undefined,
          homeboundDate:
            !isOneWay && travelDatesResult?.homeboundTravelDates && travelDatesResult.homeboundTravelDates?.defaultDate
              ? new Date(travelDatesResult.homeboundTravelDates.defaultDate)
              : undefined,
        },
        vehicleTypesResult,
        {
          adults: vehicleTypesResult.selectedPassengers?.adults || 0,
          children: vehicleTypesResult.selectedPassengers?.children || 0,
          childrenAges: vehicleTypesResult.selectedPassengers?.childrenAges || [],
          pets: vehicleTypesResult.selectedPassengers?.pets || 0,
        },
        selectedCurrency,
        isOneWay
      )
    }
  }, [travelSearchConfigResult.isLoading, routesApiData, vehicleTypesResult.isLoading, travelDatesResult.isLoading])

  useEffect(() => {
    getTrackingSession() && isOneWay != undefined && TrackOneWay(isOneWay)
  }, [isOneWay])

  useEffect(() => {
    getTrackingSession() && selectedPassengers && TrackPassengers(selectedPassengers)
  }, [selectedPassengers?.adults, selectedPassengers?.children, selectedPassengers?.pets])

  useEffect(() => {
    const routeCode = selectedRoute?.routeCode
    const vehicleCode = vehicleTypesResult.selectedVehicle?.code

    if (!routeCode || vehicleTypesResult.isLoading || !routesApiData || !vehicleCode) return

    const multipleVehiclesSupportedWithRoute = isMultipleVehclesSupportedWithRoute(routeCode)
    const multipleVehiclesSupportedWithVehicle = isMultipleVehclesSupportedWithVehicleType(vehicleCode)

    const shouldMultipleVehiclesBeSupported = multipleVehiclesSupportedWithRoute && multipleVehiclesSupportedWithVehicle

    if (multipleVehiclesSupported !== shouldMultipleVehiclesBeSupported) {
      dispatch({
        type: 'SET_MULTIPLE_VEHICLES_SUPPORTED',
        supported: shouldMultipleVehiclesBeSupported,
      })
    }

    if (!shouldMultipleVehiclesBeSupported && multipleVehiclesChecked) {
      dispatch({
        type: 'SET_MULTIPLE_VEHICLES_CHECKED',
        checked: false,
      })
    }

    const petsOnlyAllowed = isPetsOnlyAllowedInVehicle(routeCode)
    dispatch({
      type: 'SET_PETS_ONLY_ALLOWED_IN_VEHICLE',
      allowed: petsOnlyAllowed,
    })
  }, [
    selectedRoute?.routeCode,
    vehicleTypesResult.selectedVehicle?.code,
    !routesApiData,
    vehicleTypesResult.isLoading,
    multipleVehiclesChecked,
    multipleVehiclesSupported,
    dispatch,
  ])

  useEffect(() => {
    if (selectedRoute?.routeCode && !vehicleTypesResult.isLoading && getTrackingSession()) {
      TrackRoute(selectedRoute, vehicleTypesResult)
    }
  }, [selectedRoute, vehicleTypesResult.isLoading])

  useEffect(() => {
    if (selectedVehicle && !initialLoad) {
      setInitialLoad(true)
    }
  }, [selectedVehicle])

  const trackVehicleDebounce = useCallback(
    debounce((vehicleTypesResult: UseVehiclesResult) => TrackVehicle(vehicleTypesResult), 400),
    []
  )

  useEffect(() => {
    initialLoad &&
      getTrackingSession() &&
      !isFrsRoute(selectedRoute?.routeCode ?? '') &&
      selectedVehicle &&
      selectedVehicle.count &&
      vehicleTypesResult &&
      !vehicleTypesResult.isLoading &&
      trackVehicleDebounce(vehicleTypesResult)
  }, [selectedVehicle?.code, selectedVehicle?.count, selectedVehicle?.trailer])

  useEffect(() => {
    getTrackingSession() && selectedDates && TrackDates(selectedDates, false)
  }, [selectedDates])

  useEffect(() => {
    const returnRequired = selectedRoute?.returnRequired ?? routesApiData?.categories?.[0]?.routes?.[0]?.returnRequired ?? false

    if (returnRequired !== disableOneWay) {
      dispatch({ type: 'SET_DISABLE_ONE_WAY', disabled: returnRequired })
    }
  }, [selectedRoute?.returnRequired, routesApiData?.categories?.[0]?.routes?.[0]?.returnRequired, disableOneWay])

  const bookingUrlResult = useBookingUrl(vehicleTypesResult, travelDatesResult, selectedCurrency)

  const isLoading: boolean =
    travelSearchConfigResult?.isLoading ||
    travelDatesResult?.isLoading ||
    !routesApiData ||
    vehicleTypesResult?.isLoading ||
    toShortDateString(travelDatesResult?.selectedOutboundDate) === '-'

  useEffect(() => {
    if (!travelSearchConfigResult.isLoading && !!routesApiData) {
      if (travelSearchConfigResult.isMultiOffer !== undefined) {
        setTimeout(() => {
          onReady()
          setInitialLoading(false)
        }, 1000)
      } else {
        if (window.location.hostname !== 'www.dfds.com') {
          setErrorMessage(
            `AvailableMinibooks: ${travelSearchConfigResult.availableMinibooks}, isMultiOffer: ${travelSearchConfigResult.isMultiOffer}`
          )
        }
        onReady()
        setInitialLoading(false)
      }
    }
  }, [travelSearchConfigResult])

  if (errorMessage) {
    return (
      <div className={clsx(`ts-relative ts-p-dfds-s lg:ts-p-dfds-xs ts-h-[127px]`)}>
        <div className="ts-w-full ts-h-full ts-flex ts-text-dfds-text-light-primary">
          <span className="ts-mt-auto">{window.location.hostname !== 'www.dfds.com' && errorMessage}</span>
        </div>
      </div>
    )
  }

  return (
    <div ref={travelSearchRef} id="travel-search-container" className={clsx(`ts-relative`)}>
      <StickyButton
        offersShown={showMultiOffer === true}
        shown={inView && activeSelectField === ActiveSelectField.NONE}
        onClick={() => {
          if (showMultiOffer === true) {
            setShowMultiOfferModal(true)
            return
          }
          travelSearchRef?.current?.scrollIntoView({ behavior: 'smooth' })
        }}
      />

      <motion.div
        ref={stickyTravelSearchRef}
        initial={{
          top: inView ? 0 : -height,
        }}
        animate={{
          top: inView ? 0 : -height,
        }}
        className={clsx(inView && 'lg:ts-bg-dfds-surface-tertiary ts-z-10 lg:ts-fixed lg:ts-left-0 lg:ts-right-0 lg:ts-top-0 lg:ts-grid')}
      >
        <div className={clsx(inView && 'md:ts-mx-[40px] ts-w-full xl:ts-max-w-[1200px] xl:ts-mx-auto ts-py-dfds-s')}>
          {!travelSearchConfigResult.isLoading && showMultiOffer && typeof travelSearchConfigResult.availableMinibooks != 'string' ? (
            <>
              <MultiOffer
                offerModalShown={showMultiOfferModal}
                route={travelSearchConfigResult.route || ''}
                optionalOfferTitle={travelSearchConfigResult.optionalOfferTitle}
                productLists={travelSearchConfigResult.availableMinibooks}
                onProductClicked={(_minibookId: string) => {
                  setMinibookId(_minibookId)
                  setShowMultiOffer(false)
                }}
                onRequestOfferModal={setShowMultiOfferModal}
                inView={inView}
              />
            </>
          ) : (
            <>
              {!initialLoading && travelSearchConfigResult.isMultiOffer && (
                <div className="ts-flex">
                  <BackToMultiOffer
                    isMultiOffer={travelSearchConfigResult.isMultiOffer}
                    onMultiOfferClicked={() => {
                      setShowMultiOffer(true)
                      setShowMultiOfferModal(false)
                    }}
                  />
                </div>
              )}

              {!initialLoading && (
                <div className="ts-mb-dfds-xs">
                  <OnewayReturn onewayDisabled={disableOneWay} />
                </div>
              )}
              {!initialLoading && (
                <div className="ts-grid ts-grid-cols-12 lg:ts-grid-cols-6 lg:ts-grid-rows-1 lg:ts-gap-[0px] ts-gap-dfds-xs ts-bg-transparent">
                  {/* Routes select field */}
                  <div className="ts-h-[58px] ts-w-full lg:ts-col-auto md:ts-col-span-6 ts-col-span-12 ts-box-border ts-border lg:ts-border-r-0 ts-border-solid ts-border-cvi-grey-grey200">
                    <SelectField
                      dataAttrList={[
                        { name: 'selected-route', value: `${selectedRoute?.routeCode}` },
                        { name: 'selected-salesowner', value: `${selectedRoute?.salesOwner}` },
                      ]}
                      disabled={routesApiData?.categories?.reduce((count, current) => count + current.routes.length, 0) === 1}
                      title={t('ROUTE')}
                      isLoading={travelSearchConfigResult?.isLoading || !routesApiData}
                      isMobile={isMobile}
                      isOpen={activeSelectField === ActiveSelectField.ROUTE}
                      fieldName="route"
                      value={selectedRoute?.name}
                      icon={isOneWay ? <RoroFerryIcon size="md" /> : <FerryReturnIcon size="md" />}
                      children={
                        <RoutesWithSearch
                          routeCategories={routesApiData?.categories ?? []}
                          isOpen={activeSelectField === ActiveSelectField.ROUTE}
                          isScrollable={routeSelectorScrollable}
                          onSelectRoute={(route) => {
                            dispatch({
                              type: 'SET_ROUTE',
                              route: {
                                routeCode: route.routeCode,
                                salesOwner: route.salesOwner,
                                name: route?.name,
                                returnRequired: route.returnRequired,
                              },
                            })
                            setActiveSelectField(ActiveSelectField.NONE)
                          }}
                        />
                      }
                      onClick={(clickedOutside: boolean) => {
                        if (clickedOutside) {
                          setActiveSelectField(ActiveSelectField.NONE)
                        } else {
                          !!routesApiData && setActiveSelectField(ActiveSelectField.ROUTE)
                        }
                      }}
                      onClose={() => {
                        activeSelectField === ActiveSelectField.ROUTE && setActiveSelectField(ActiveSelectField.NONE)
                      }}
                    />
                  </div>
                  {/* Vehicles select field */}
                  <div
                    className={clsx(
                      'ts-h-[58px] ts-w-full ts-justify-center md:ts-col-span-6 ts-col-span-12 lg:ts-col-auto ts-box-border ts-border lg:ts-border-r-0 ts-border-solid ts-border-cvi-grey-grey200',
                      isFrsRoute(selectedRoute?.routeCode ?? '') && 'ts-hidden'
                    )}
                  >
                    <SelectField
                      dataAttrList={[
                        { name: 'selected-vehicle', value: `${vehicleTypesResult?.selectedVehicle?.code}` },
                        { name: 'selected-trailer', value: `${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}` },
                      ]}
                      disabled={
                        vehicleTypesResult?.vehicleApiData?.vehicleTypes?.reduce((count, current) => count + current.code.length, 0) ===
                          1 ||
                        (vehicleTypesResult?.vehicleApiData?.vehicleTypes?.reduce((count, current) => count + current.code.length, 0) ===
                          0 &&
                          vehicleTypesResult.selectedVehicle.code === 'NCAR')
                      }
                      title={t('VEHICLE')}
                      isLoading={travelSearchConfigResult?.isLoading || !routesApiData || vehicleTypesResult.isLoading}
                      isMobile={isMobile}
                      isOpen={activeSelectField === ActiveSelectField.VEHICLE}
                      fieldName="vehicle"
                      value={
                        vehicleTypesResult.selectedVehicle.selectedTrailer
                          ? `${getMultipleVehicleSelectedCount(vehicleTypesResult?.selectedVehicle?.count)}${t(`VEHICLE-NAME-${vehicleTypesResult?.selectedVehicle?.code}`)} + ${getMultipleVehicleSelectedCount(vehicleTypesResult?.selectedVehicle?.count)} ${t(
                              `TRAILER-NAME-${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}`
                            )}`
                          : `${getMultipleVehicleSelectedCount(vehicleTypesResult?.selectedVehicle?.count)}${t(`VEHICLE-NAME-${vehicleTypesResult?.selectedVehicle?.code}`)}`
                      }
                      icon={
                        vehicleTypesResult.selectedVehicle?.code && (
                          <VehicleIcon code={vehicleTypesResult?.selectedVehicle?.code} size="md" />
                        )
                      }
                      visibleRows={vehicleTypesResult.selectedVehicle && vehicleTypesResult.selectedVehicle.selectedTrailer ? 2 : 1}
                      children={
                        <Vehicles
                          dataAttrList={[
                            { name: 'selected-vehicle', value: `${vehicleTypesResult?.selectedVehicle?.code}` },
                            { name: 'selected-trailer', value: `${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}` },
                          ]}
                          isLoading={travelSearchConfigResult?.isLoading || !routesApiData || vehicleTypesResult.isLoading}
                          isVehicleMandatory={vehicleTypesResult.vehicleApiData?.isVehicleMandatory || false}
                          vehicleTypes={vehicleTypesResult.vehicleApiData?.vehicleTypes}
                          selectedVehicle={vehicleTypesResult.selectedVehicle}
                          selectedTrailer={vehicleTypesResult?.selectedVehicle?.selectedTrailer}
                          selectedTrailerNoLongerAvailable={selectedTrailerNoLongerAvailable}
                          selectedCategory={selectedCategory}
                          availableTrailers={
                            vehicleTypesResult.vehicleApiData?.vehicleTypes.find(
                              (x: any) => x.code === vehicleTypesResult.selectedVehicle.code
                            )?.availableTrailers || []
                          }
                          multipleVehiclesChecked={multipleVehiclesChecked}
                          multipleVehiclesSupported={multipleVehiclesSupported}
                          onCheckMultipleVehicle={(multipleVehicleChecked: boolean) => {
                            if (!multipleVehicleChecked && selectedVehicle) {
                              // Reset vehicle count
                              dispatch({ type: 'SET_VEHICLE', vehicle: { ...selectedVehicle, count: 1 } })
                            }
                            dispatch({
                              type: 'SET_MULTIPLE_VEHICLES_CHECKED',
                              checked: multipleVehicleChecked,
                            })
                            TrackMultipleVehicles(multipleVehicleChecked)
                          }}
                          onSelectCategory={(categoryCode) => {
                            if (categoryCode === 'NCAR') {
                              dispatch({
                                type: 'SET_VEHICLE',
                                vehicle: {
                                  code: 'NCAR',
                                  trailer: undefined,
                                  categoryCode: undefined,
                                  count: 0,
                                },
                              })
                              dispatch({
                                type: 'SET_MULTIPLE_VEHICLES_SUPPORTED',
                                supported: false,
                              })
                              dispatch({
                                type: 'SET_MULTIPLE_VEHICLES_CHECKED',
                                checked: false,
                              })
                            } else setSelectedCategory(categoryCode)
                          }}
                          onSelectVehicle={(vehicle, count, categoryCode, activeTrailer) => {
                            const availableTrailers =
                              vehicleTypesResult.vehicleApiData?.vehicleTypes.find((x: any) => x.code === vehicle)?.availableTrailers || []
                            let trailer = vehicleTypesResult?.selectedVehicle?.selectedTrailer
                            if (
                              availableTrailers?.length > 0 &&
                              !availableTrailers.some((x) => x.code === vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code)
                            ) {
                              setSelectedTrailerNoLongerAvailable(vehicleTypesResult?.selectedVehicle?.selectedTrailer)
                              trailer = undefined
                            } else setSelectedTrailerNoLongerAvailable(undefined)
                            dispatch({
                              type: 'SET_VEHICLE',
                              vehicle: {
                                code: vehicle,
                                categoryCode: categoryCode,
                                trailer: trailer ?? activeTrailer,
                                count: count,
                              },
                            })

                            if (selectedRoute?.routeCode) {
                              const isMultipleVehclesSupportedWithVehicle = isMultipleVehclesSupportedWithVehicleType(vehicle)
                              const isMultipleVehclesSupportedWithSelectedRoute = isMultipleVehclesSupportedWithRoute(
                                selectedRoute?.routeCode
                              )
                              dispatch({
                                type: 'SET_MULTIPLE_VEHICLES_SUPPORTED',
                                supported: isMultipleVehclesSupportedWithVehicle && isMultipleVehclesSupportedWithSelectedRoute,
                              })
                            }
                          }}
                          onSelectTrailer={(trailer) => {
                            setSelectedTrailerNoLongerAvailable(undefined)
                            dispatch({
                              type: 'SET_VEHICLE',
                              vehicle: {
                                code: vehicleTypesResult?.selectedVehicle?.code,
                                trailer: trailer,
                                count: selectedVehicle?.count ?? 1,
                              },
                            })
                          }}
                          onFooterClick={() => setActiveSelectField(ActiveSelectField.NONE)}
                        />
                      }
                      onClick={(clickedOutside: boolean) => {
                        if (clickedOutside) {
                          setActiveSelectField(ActiveSelectField.NONE)
                        } else {
                          !!routesApiData && !vehicleTypesResult.isLoading && setActiveSelectField(ActiveSelectField.VEHICLE)
                        }
                      }}
                      onClose={() => {
                        activeSelectField === ActiveSelectField.VEHICLE && setActiveSelectField(ActiveSelectField.NONE)
                      }}
                    />
                  </div>
                  {/* Passengers select field */}
                  <div
                    className={clsx(
                      'ts-h-[58px] ts-w-full ts-col-span-12 md:ts-col-span-6 lg:ts-col-auto ts-box-border ts-border lg:ts-border-r-0 ts-border-solid ts-border-cvi-grey-grey200',
                      isFrsRoute(selectedRoute?.routeCode ?? '') && 'ts-hidden'
                    )}
                  >
                    <SelectField
                      dataAttrList={[
                        { name: 'selected-adults', value: `${vehicleTypesResult?.selectedPassengers?.adults}` },
                        { name: 'selected-children', value: `${vehicleTypesResult?.selectedPassengers?.children}` },
                        { name: 'child-ages', value: `${vehicleTypesResult?.selectedPassengers?.childrenAges?.join(',')}` },
                        { name: 'selected-pets', value: `${vehicleTypesResult?.selectedPassengers?.pets}` },
                      ]}
                      title={`${t('PASSENGERS')} & ${t('PETS')}`}
                      isLoading={travelSearchConfigResult?.isLoading || !routesApiData || vehicleTypesResult.isLoading}
                      isMobile={isMobile}
                      isOpen={activeSelectField === ActiveSelectField.PASSENGER}
                      fieldName="passenger"
                      value={
                        vehicleTypesResult?.selectedPassengers?.pets && vehicleTypesResult?.selectedPassengers?.pets > 0
                          ? `${vehicleTypesResult.numberOfPassengers} + ${t('PETS')}`
                          : vehicleTypesResult.numberOfPassengers
                      }
                      icon={<PassengerIcon size="md" />}
                      visibleRows={vehicleTypesResult.selectedPassengers?.adults && vehicleTypesResult.selectedPassengers.pets ? 2 : 1}
                      children={
                        <Passengers
                          dataAttrList={[
                            { name: 'selected-adults', value: `${vehicleTypesResult?.selectedPassengers?.adults}` },
                            { name: 'selected-children', value: `${vehicleTypesResult?.selectedPassengers?.children}` },
                            { name: 'child-ages', value: `${vehicleTypesResult?.selectedPassengers?.childrenAges?.join(',')}` },
                            { name: 'selected-pets', value: `${vehicleTypesResult?.selectedPassengers?.pets}` },
                          ]}
                          isLoading={travelSearchConfigResult?.isLoading || !routesApiData || vehicleTypesResult.isLoading}
                          adults={vehicleTypesResult?.selectedPassengers?.adults ?? 1}
                          children={vehicleTypesResult?.selectedPassengers?.children ?? 0}
                          childrenAges={vehicleTypesResult?.selectedPassengers?.childrenAges}
                          pets={vehicleTypesResult?.selectedPassengers?.pets ?? 0}
                          maxPax={vehicleTypesResult?.maxNumberOfPassengers}
                          maxPets={vehicleTypesResult?.vehicleApiData?.maxNumberOfPets}
                          petsAllowed={vehicleTypesResult.petsAllowed}
                          petsOnlyAllowedInVehicle={petsOnlyAllowedInVehicle}
                          selectedRoute={selectedRoute?.name ?? ''}
                          onQuantityChange={(adults: number, children: number, pets: number, childrenAges: number[]) => {
                            if (childrenAges && childrenAges.length < children) {
                              childrenAges = [...childrenAges, 8]
                            } else if (childrenAges && childrenAges.length > children) {
                              childrenAges = childrenAges.slice(0, children)
                            }
                            dispatch({
                              type: 'SET_PASSENGERS',
                              passengers: {
                                adults: adults,
                                children: children,
                                pets: pets,
                                childrenAges: childrenAges || [],
                              },
                            })
                          }}
                          onFooterClick={() => setActiveSelectField(ActiveSelectField.NONE)}
                        />
                      }
                      onClick={(clickedOutside: boolean) => {
                        if (clickedOutside) {
                          setSelectedTrailerNoLongerAvailable(undefined)
                          setActiveSelectField(ActiveSelectField.NONE)
                        } else {
                          !!routesApiData && !vehicleTypesResult.isLoading && setActiveSelectField(ActiveSelectField.PASSENGER)
                        }
                      }}
                      onClose={() => {
                        activeSelectField === ActiveSelectField.PASSENGER && setActiveSelectField(ActiveSelectField.NONE)
                      }}
                    />
                  </div>
                  {/* Currency select field */}
                  <div
                    className={clsx(
                      'ts-h-[58px] ts-w-full ts-col-span-12 md:ts-col-span-6 lg:ts-col-auto ts-box-border ts-border lg:ts-border-r-0 ts-border-solid ts-border-cvi-grey-grey200',
                      !isFrsRoute(selectedRoute?.routeCode ?? '') && 'ts-hidden'
                    )}
                  >
                    {initialLoading ? (
                      <Skeleton className="ts-h-full ts-w-full" square />
                    ) : (
                      <SelectField
                        dataAttrList={[{ name: 'selected-currency', value: `${selectedCurrency}` }]}
                        disabled={!isFrsRoute(selectedRoute?.routeCode ?? '')}
                        title={t('CURRENCY')}
                        isLoading={travelSearchConfigResult?.isLoading || !routesApiData}
                        isMobile={isMobile}
                        isOpen={activeSelectField === ActiveSelectField.CURRENCY}
                        fieldName="currency"
                        value={mapToCurrencyToContentfulResource(selectedCurrency, t)}
                        icon={<CurrencyIcon size="md" />}
                        children={
                          <Currency
                            selectedCurrency={selectedCurrency}
                            onSelectCurrency={(currency) => {
                              dispatch({ type: 'SET_CURRENCY', currency })
                              setActiveSelectField(ActiveSelectField.NONE)
                            }}
                          />
                        }
                        onClick={(clickedOutside: boolean) => {
                          if (clickedOutside) {
                            setActiveSelectField(ActiveSelectField.NONE)
                          } else {
                            !!routesApiData && setActiveSelectField(ActiveSelectField.CURRENCY)
                          }
                        }}
                        onClose={() => {
                          activeSelectField === ActiveSelectField.ROUTE && setActiveSelectField(ActiveSelectField.NONE)
                        }}
                      />
                    )}
                  </div>
                  <div className="ts-h-[58px] ts-w-full ts-col-span-12 md:ts-col-span-6 lg:ts-col-span-2 ts-box-border ts-border lg:ts-border-r-0 ts-border-solid ts-border-cvi-grey-grey200">
                    <DateField
                      dataAttrList={[
                        {
                          name: 'selected-outbound-date',
                          value:
                            travelDatesResult?.selectedOutboundDate && `${format(travelDatesResult?.selectedOutboundDate, 'yyyy-MM-dd')}`,
                        },
                        {
                          name: 'selected-homebound-date',
                          value:
                            travelDatesResult?.selectedHomeboundDate && `${format(travelDatesResult?.selectedHomeboundDate, 'yyyy-MM-dd')}`,
                        },
                      ]}
                      isLoading={
                        travelSearchConfigResult?.isLoading || !routesApiData || vehicleTypesResult.isLoading || travelDatesResult.isLoading
                      }
                      disabled={
                        isOneWay
                          ? travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                            travelDatesResult?.outboundTravelDates?.dates?.toDate
                          : travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                              travelDatesResult?.outboundTravelDates?.dates?.toDate &&
                            travelDatesResult?.homeboundTravelDates?.dates?.fromDate ===
                              travelDatesResult?.homeboundTravelDates?.dates?.toDate
                      }
                      showFooter={false}
                      returnFocused={returnFocused}
                      isOpen={activeSelectField === ActiveSelectField.DATE}
                      isOneway={isOneWay}
                      fieldName="date"
                      selectedOutboundDate={travelDatesResult.selectedOutboundDate}
                      selectedHomeboundDate={travelDatesResult.selectedHomeboundDate}
                      containerRef={selectorContainer}
                      onClick={(clickedOutside: boolean, clickedReturnField?: boolean) => {
                        if (
                          isOneWay
                            ? !(
                                travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                                travelDatesResult?.outboundTravelDates?.dates?.toDate
                              )
                            : !(
                                travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                                  travelDatesResult?.outboundTravelDates?.dates?.toDate &&
                                travelDatesResult?.homeboundTravelDates?.dates?.fromDate ===
                                  travelDatesResult?.homeboundTravelDates?.dates?.toDate
                              )
                        ) {
                          if (isOneWay && clickedReturnField) {
                            setShowReturnChanged(true)
                            return
                          }

                          if (clickedOutside) {
                            setActiveSelectField(ActiveSelectField.NONE)
                            setReturnFocused(false)
                          } else {
                            !!routesApiData &&
                              !vehicleTypesResult.isLoading &&
                              !travelDatesResult.isLoading &&
                              setActiveSelectField(ActiveSelectField.DATE)
                          }
                        }
                      }}
                      onClose={() => {
                        activeSelectField === ActiveSelectField.DATE && setActiveSelectField(ActiveSelectField.NONE)
                        setReturnFocused(false)
                      }}
                    >
                      {isOneWay && (
                        <DatePicker
                          newStyles={true}
                          locale={getDatepickerLocale()}
                          travelDates={travelDatesResult?.outboundTravelDates}
                          selectedDate={travelDatesResult.selectedOutboundDate}
                          onDateChanged={(date: Date | undefined) => {
                            if (date) {
                              dispatch({
                                type: 'SET_DATES',
                                dates: { outboundDate: date, homeboundDate: undefined },
                              })
                              setActiveSelectField(ActiveSelectField.NONE)
                            }
                          }}
                        />
                      )}

                      {!isOneWay && (
                        <DateRangePicker
                          newStyles={true}
                          locale={getDatepickerLocale()}
                          returnFocused={returnFocused}
                          isLoading={travelDatesResult.isLoading}
                          outboundTravelDates={travelDatesResult?.outboundTravelDates}
                          homeboundTravelDates={travelDatesResult?.homeboundTravelDates}
                          selectedDates={{
                            from: travelDatesResult.selectedOutboundDate,
                            to: travelDatesResult.selectedHomeboundDate,
                          }}
                          outboundDateChanged={(date: Date) => {
                            dispatch({
                              type: 'SET_DATES',
                              dates: { outboundDate: date, homeboundDate: date },
                            })
                            setReturnFocused(true)
                          }}
                          returnDateChanged={(date: Date) => {
                            dispatch({
                              type: 'SET_DATES',
                              dates: { outboundDate: travelDatesResult.selectedOutboundDate, homeboundDate: date },
                            })
                            setReturnFocused(false)
                            setActiveSelectField(ActiveSelectField.NONE)
                          }}
                          onClose={() => {
                            setActiveSelectField(ActiveSelectField.NONE)
                            setReturnFocused(false)
                          }}
                        />
                      )}
                    </DateField>
                  </div>

                  {isFrsRoute(selectedRoute?.routeCode ?? '') && <div className="ts-col-span-1"></div>}
                  <div className="ts-col-span-12 lg:ts-col-span-1 dfds-orange-button">
                    {bookingUrlResult.isLoading || isLoading ? (
                      <div className="ts-flex ts-font-medium ts-items-center ts-justify-center ts-h-[54px] md:ts-h-[58px] ts-w-full ts-box-border ts-border ts-border-solid ts-border-cvi-brand-brand500 ts-bg-cvi-brand-brand500 dfds-orange-button ts-text-white ts-cursor-not-allowed">
                        <Spinner height={30} width={30} className="ts-animate-spin ts-duration-700" />
                      </div>
                    ) : (
                      <a
                        className={clsx(
                          clsx(
                            'travel-search-a ts-text-base ts-font-medium ts-relative ts-cursor-pointer dfds-orange-button hover:ts-opacity-90 active:ts-opacity-80 ts-justify-center ts-box-border ts-bg-cvi-brand-brand500 ts-text-white hover:ts-text-white ts-text-center ts-flex ts-px-dfds-s ts-items-center ts-h-[54px] md:ts-h-[58px] ts-border ts-border-solid ts-border-cvi-brand-brand500 active:ts-text-white visited:ts-text-white ts-rounded-none'
                          )
                        )}
                        onClick={async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                          TrackingCommand(
                            TravelSearchTrackingTypes.SUBMIT,
                            selectedRoute,
                            {
                              outboundDate: travelDatesResult.selectedOutboundDate,
                              homeboundDate: travelDatesResult.selectedHomeboundDate,
                            },
                            vehicleTypesResult,
                            {
                              adults: vehicleTypesResult.selectedPassengers?.adults || 0,
                              children: vehicleTypesResult.selectedPassengers?.children || 0,
                              childrenAges: vehicleTypesResult.selectedPassengers?.childrenAges || [],
                              pets: vehicleTypesResult.selectedPassengers?.pets || 0,
                            },
                            selectedCurrency,
                            isOneWay
                          )
                          if (bookingUrlResult?.url?.includes('yourbooking')) {
                            const logBody: LoggingInterfaces.LogProps = {
                              message: 'PAX-OB booking url from travel-search',
                              messageTemplate: 'PAX-OB booking url from travel-search',
                              level: 'Information',
                              fields: {
                                correlationId: uuidv4(),
                                sessionId: getSession()?.sessionId,
                                application: 'booking-flow', // booking-flow is used here to be able to filter on same application in kibana
                                environment: getClientEnvironment(),
                                frontend: true,
                                path: bookingUrlResult.url,
                                page: window.location?.pathname,
                                referer: document?.referrer,
                                userAgent: navigator?.userAgent,
                              },
                            }
                            await loggerAsync(getClientEnvironment(), logBody)
                          }
                          window.open(bookingUrlResult.url, '_self')
                          e?.preventDefault()
                        }}
                        data-e2e="booking-link"
                        href={bookingUrlResult.url}
                      >
                        <span className="ts-max-w-[200px] ts-truncate">{t('SEE-PRICES')}</span>
                      </a>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </motion.div>
    </div>
  )
}

export default TravelSearch

enum ActiveSelectField {
  ROUTE,
  VEHICLE,
  PASSENGER,
  DATE,
  CURRENCY,
  NONE,
}
