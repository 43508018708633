import { useTranslation } from 'react-i18next'
import Modal from '../Shared/Modal/Modal'
import { ProductListSections } from '../../../api/travel-search-config'
import clsx from 'clsx'
import ChevronRightIcon from '../../Icons/ChevronRightIcon'

interface Props {
  show: boolean
  productLists: ProductListSections[]
  onProductClicked: (minibookId: string) => void
  onClose: () => void
}

const MultiOfferModal: React.FC<Props> = ({ show, productLists, onClose, onProductClicked }) => {
  const { t } = useTranslation()

  return (
    <Modal
      fullscreenMobile={true}
      noPadding={true}
      data-e2e="multiofferModal"
      headline={t('SEE-OFFERS')}
      isOpen={show}
      onCloseRequested={() => onClose()}
    >
      {productLists.map((productList, plists_index) => (
        <div key={plists_index} data-e2e="multioffer-productList">
          <div
            key={plists_index}
            className={clsx('ts-font-medium ts-text-dfds-text-dark-primary', productList.productSectiontitle && 'ts-p-dfds-s')}
          >
            {productList.productSectiontitle}
          </div>
          <div className="ts-flex ts-flex-1 ts-flex-col">
            {productList.minibookProductListItems.map((product, plist_index) => (
              <li
                key={plist_index}
                data-e2e="multioffer-product"
                className={`hover:ts-bg-cvi-brand-brand100 ts-border-0 ts-m-0 ts-group ts-flex ts-cursor-pointer ts-list-none ts-flex-row ts-items-center ts-justify-between ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-p-dfds-s md:ts-w-[500px] lg:hover:ts-bg-dfds-listitem-hover ${
                  plist_index === productList.minibookProductListItems.length - 1 &&
                  plists_index === productLists.length - 1 &&
                  'ts-border-b-0'
                }`}
                onClick={() => onProductClicked(product.minibookContentReference)}
              >
                <div className="ts-flex ts-flex-col">
                  <label className="ts-text-cvi-brand-brand900 ts-cursor-pointer">{product.offertitle}</label>
                  <div className="ts-text-cvi-grey-grey700">{product.productDetails}</div>
                  <label className="ts-text-cvi-brand-brand900 ts-cursor-pointer ts-font-medium">{product.offerDetails}</label>
                </div>
                <ChevronRightIcon className="ts-text-cvi-brand-brand900" size="lg" />
              </li>
            ))}
          </div>
        </div>
      ))}
    </Modal>
  )
}

export default MultiOfferModal
