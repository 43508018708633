import clsx from 'clsx'
import React from 'react'
import { IconProps } from '../Utils/common'
const ChevronUpIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5901 16L12.0001 11.0553L7.41006 16L6.00006 14.4777L12.0001 8L18.0001 14.4777L16.5901 16Z"
        fill="currentColor"
      />
    </svg>
  )
})
ChevronUpIcon.displayName = 'ChevronUpIcon'
export default ChevronUpIcon
