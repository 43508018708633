import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const BusIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10.5L17 12.5H24V19H16.011C16.011 16.79 14.222 15 12.0165 15C9.8105 15 8.0215 16.79 8.0215 19H0V10.5H2V6H0V2H20.0055C22.1475 2 23.9945 3.84 23.9995 6H15V10.5ZM4 10.5H13V6H4V10.5ZM9.87868 16.8787C10.4413 16.3161 11.2044 16 12 16C12.7956 16 13.5587 16.3161 14.1213 16.8787C14.6839 17.4413 15 18.2043 15 19C15 19.7957 14.6839 20.5587 14.1213 21.1213C13.5587 21.6839 12.7956 22 12 22C11.2044 22 10.4413 21.6839 9.87868 21.1213C9.31607 20.5587 9 19.7957 9 19C9 18.2043 9.31607 17.4413 9.87868 16.8787ZM11.5408 20.1087C11.6864 20.169 11.8424 20.2 12 20.2C12.3183 20.2 12.6235 20.0736 12.8485 19.8485C13.0736 19.6235 13.2 19.3183 13.2 19C13.2 18.6817 13.0736 18.3765 12.8485 18.1515C12.6235 17.9264 12.3183 17.8 12 17.8C11.8424 17.8 11.6864 17.831 11.5408 17.8913C11.3952 17.9516 11.2629 18.04 11.1515 18.1515C11.04 18.2629 10.9516 18.3952 10.8913 18.5408C10.831 18.6864 10.8 18.8424 10.8 19C10.8 19.1576 10.831 19.3136 10.8913 19.4592C10.9516 19.6048 11.04 19.7371 11.1515 19.8485C11.2629 19.96 11.3952 20.0484 11.5408 20.1087Z"
        fill="currentColor"
      />
    </svg>
  )
})
BusIcon.displayName = 'BusIcon'
export default BusIcon
