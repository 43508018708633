import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const CarRoofBoxIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_3_1383)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 19.5C9.5 17.291 11.291 15.5 13.5 15.5C15.709 15.5 17.5 17.291 17.5 19.5C17.5 21.709 15.709 23.496 13.5 23.496C11.291 23.496 9.5 21.709 9.5 19.5ZM11.5 19.5C11.5 20.6025 12.3975 21.5 13.5 21.5C14.6025 21.5 15.5 20.6025 15.5 19.5C15.5 18.3975 14.6025 17.5 13.5 17.5C12.3975 17.5 11.5 18.3975 11.5 19.5ZM8.5 0L12.5 2V3.5H0V0H8.5ZM24 11V12H21.5V13.5H24V17.5L22.5 19H18.475C18.2225 16.4785 16.086 14.5 13.5 14.5C10.914 14.5 8.7775 16.4785 8.525 19H0V13H2.5V12H0V9.53402H9.985L7.4945 6.04002L0 6.03402V4.49152L8.5 4.50002L12 9.50802L22.5 9.50002L24 11Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1383">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
})
CarRoofBoxIcon.displayName = 'CarRoofBoxIcon'
export default CarRoofBoxIcon
