import clsx from 'clsx'
import { forwardRef } from 'react'
import MinusIcon from '../../Icons/MinusIcon'
import PlusIcon from '../../Icons/PlusIcon'

interface CounterProps {
  title?: string
  description?: string
  defaultValue: number
  downDisabled: boolean
  upDisabled: boolean
  ageRange?: string
  e2eAttribute: string
  onValueIncrease: () => void
  onValueDecrease: () => void
}

export const Counter = forwardRef<HTMLInputElement, CounterProps>(
  (
    { title, description, defaultValue, downDisabled, upDisabled, ageRange, e2eAttribute, onValueDecrease, onValueIncrease, ...rest },
    ref
  ) => {
    return (
      <div className="ts-inline-flex">
        <div className="ts-flex ts-flex-row ts-items-center">
          <div
            onClick={downDisabled ? undefined : onValueDecrease}
            className="ts-min-w-[60px] ts-min-h-[60px] ts-flex ts-items-center ts-justify-center"
          >
            <button
              data-e2e={`${e2eAttribute}-down`}
              disabled={downDisabled}
              className={clsx(
                'travel-search-button ts-text-white ts-rounded-full ts-inline-flex ts-h-dfds-l ts-w-dfds-l ts-items-center ts-justify-center',
                downDisabled ? 'ts-bg-cvi-grey-grey100' : 'ts-bg-cvi-brand-brand900'
              )}
            >
              <MinusIcon size="md" className={downDisabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} />
            </button>
          </div>

          <input
            ref={ref}
            {...rest}
            disabled={true}
            data-e2e={`${e2eAttribute}-input`}
            className="travel-search-input lg:ts-mx-dfds-xs ts-h-[40px] ts-border-none ts-w-[20px] disabled:ts-text-cvi-brand-brand900 disabled:ts-opacity-100 ts-appearance-none ts-border ts-border-dfds-text-dark-primary ts-bg-transparent ts-text-center ts-text-cvi-brand-brand900 ts-font-medium tabletDown:ts-mx-dfds-xs"
            type="number"
            defaultValue={defaultValue}
          />
          <div
            onClick={upDisabled ? undefined : onValueIncrease}
            className="ts-min-w-[60px] ts-min-h-[60px] ts-flex ts-items-center ts-justify-center"
          >
            <button
              data-e2e={`${e2eAttribute}-up`}
              disabled={upDisabled}
              className={clsx(
                'travel-search-button ts-text-white ts-rounded-full ts-inline-flex ts-h-dfds-l ts-w-dfds-l ts-items-center ts-justify-center focus:ts-border-cvi-brand-brand500',
                upDisabled ? 'ts-bg-cvi-grey-grey100' : ' ts-bg-cvi-brand-brand900'
              )}
            >
              <PlusIcon size="md" className={upDisabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} />
            </button>
          </div>
        </div>
      </div>
    )
  }
)

export default Counter
