import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const RoroFerryIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        d="M10 7.5H16.5V10H10V7.5ZM17.5 7.5H24V10H17.5V7.5ZM0 11H24V13.5H0V11ZM0 20H2.5C9 20 9 23 16 23H19.5L24 14.5H0V20ZM7.5 6H4.5L3 2H0V10H9L7.5 6Z"
        fill="currentColor"
      />
    </svg>
  )
})
RoroFerryIcon.displayName = 'RoroFerryIcon'
export default RoroFerryIcon
