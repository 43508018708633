import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const PlusIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0001 13V19H13.0001V13H19V11H13.0001V5H11.0001V11H5V13H11.0001Z"
        fill="currentColor"
      />
    </svg>
  )
})
PlusIcon.displayName = 'PlusIcon'
export default PlusIcon
