export class ApiError extends Error {
  status?: number
  correlationId?: string

  constructor(message: string, status?: number, correlationId?: string) {
    super(message)
    this.name = 'ApiError'
    this.status = status
    this.correlationId = correlationId
  }
}

export async function fetcher<T = any>(url: string, options?: RequestInit): Promise<T> {
  const res = await fetch(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options?.headers || {}),
    },
  })

  const isJson = res.headers.get('content-type')?.includes('application/json')

  if (!res.ok) {
    const errorData = isJson ? await res.json() : null
    throw new ApiError(errorData?.message || res.statusText || 'Request failed', res.status, errorData?.correlationId)
  }

  return isJson ? res.json() : (res.text() as unknown as T)
}
