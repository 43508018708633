import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const MotorcycleIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_3_1399)">
        <path
          d="M17.75 11.5C17.176 11.5013 16.6049 11.5821 16.053 11.74L10.578 0H6V1.5H9.071L10.703 5H10.64C9.082 5 8.465 4.97 7 5.5C4.61 6.365 3.5 9 1.5 9H0V20H6.5C8.614 17.457 11.251 13.833 13.503 11.005L14.239 12.583C12.587 13.709 11.5 15.6045 11.5 17.75C11.5 21.1965 14.3035 24 17.75 24C21.1965 24 24 21.1965 24 17.75C24 14.3035 21.1965 11.5 17.75 11.5ZM17.75 22C15.4065 22 13.5 20.0935 13.5 17.75C13.5003 17.1121 13.6445 16.4825 13.9217 15.9081C14.199 15.3336 14.6023 14.8291 15.1015 14.432L16.8295 18.139C16.9416 18.3794 17.1445 18.5655 17.3938 18.6562C17.643 18.747 17.9181 18.7351 18.1585 18.623C18.3989 18.5109 18.585 18.308 18.6757 18.0587C18.7665 17.8095 18.7546 17.5344 18.6425 17.294L16.9125 13.584C17.1882 13.5283 17.4687 13.5002 17.75 13.5C20.0935 13.5 22 15.4065 22 17.75C22 20.0935 20.0935 22 17.75 22ZM15 7C15 6.46957 15.2107 5.96086 15.5858 5.58579C15.9609 5.21071 16.4696 5 17 5V9C16.4696 9 15.9609 8.78929 15.5858 8.41421C15.2107 8.03914 15 7.53043 15 7ZM4.5 18.5H1.5V17H4.5V18.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1399">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
})
MotorcycleIcon.displayName = 'MotorcycleIcon'
export default MotorcycleIcon
