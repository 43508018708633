import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const BicycleIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_3_1305)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.754 9.3425C13.806 8.808 14.9915 8.5 16.25 8.5C20.5235 8.5 24 11.976 24 16.2495C24 20.523 20.5235 24 16.25 24C11.9765 24 8.5 20.5235 8.5 16.25C8.5 13.772 9.6735 11.5675 11.4885 10.148L9.17 6.495L2.6975 14.6935C2.8895 15.089 3 15.531 3 16C2.99978 16.4283 2.90735 16.8516 2.729 17.241L4.568 20H6V21.5H1.5V20H2.7655L1.7305 18.448C1.2415 18.7945 0.645 19 0 19V13C0.608803 12.9994 1.20322 13.1851 1.7035 13.532L7.4525 6.25H0V4.75H8.0625L6 1.5L4 1.5005H3.824L3.823 1.5H3V0H6.824L12.754 9.3425ZM10 16.225C10 19.6855 12.7895 22.5 16.25 22.5C19.7105 22.5 22.5255 19.71 22.5255 16.2495C22.5255 12.789 19.7105 10 16.25 10C15.3185 10.0002 14.3987 10.2079 13.5575 10.608L16.8835 15.848C16.9901 16.016 17.0256 16.2195 16.9822 16.4137C16.9388 16.6079 16.82 16.7769 16.652 16.8835C16.484 16.9901 16.2805 17.0256 16.0863 16.9822C15.8921 16.9388 15.7231 16.82 15.6165 16.652L12.285 11.403C10.889 12.541 10 14.272 10 16.225ZM12.5858 4.08579C12.9609 3.71071 13.4696 3.5 14 3.5V7.5C13.4696 7.5 12.9609 7.28929 12.5858 6.91421C12.2107 6.53914 12 6.03043 12 5.5C12 4.96957 12.2107 4.46086 12.5858 4.08579Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1305">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
})
BicycleIcon.displayName = 'BicycleIcon'
export default BicycleIcon
