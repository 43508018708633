import { useEffect, useState } from 'react'
import { delay } from '../Utils'

export const useThreeDots = () => {
  const [dots, setDots] = useState<string>()

  useEffect(() => {
    setDots('.')
  }, [])

  useEffect(() => {
    const handleDots = async () => {
      await delay(500)
      if (dots === '.') setDots('..')
      if (dots === '..') setDots('...')
      if (dots === '...') setDots('.')
    }
    handleDots()
  }, [dots])

  return dots
}
