import { useEffect, useState } from 'react'
import { getBaseApi, getLocaleFromUrlOrQuery } from '../../Utils/common'
import { RoutesResponse } from './route-types'
import { fetcher } from '../../Utils/fetcher'

export const useRoutes = (minibookId?: string) => {
  const baseApi = getBaseApi()
  const locale = getLocaleFromUrlOrQuery()

  const [routesApiData, setRoutesApiData] = useState<RoutesResponse | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchRoutes = async () => {
      if (!minibookId) return
      setIsLoading(true)
      try {
        const url = `${baseApi}/routes?locale=${locale}&minibookId=${minibookId}`
        const res = await fetcher<RoutesResponse>(url)
        setRoutesApiData(res)
      } finally {
        setIsLoading(false)
      }
    }

    fetchRoutes()
  }, [minibookId])

  return {
    isLoading,
    routesApiData,
  }
}
