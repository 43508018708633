import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const FootPassengerIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_3_1374)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 4C14.6046 4 15.5 3.10457 15.5 2C15.5 0.89543 14.6046 0 13.5 0C12.3954 0 11.5 0.89543 11.5 2C11.5 3.10457 12.3954 4 13.5 4ZM21.9959 7.739L21.7189 7.4505C21.1064 6.8135 20.1384 6.827 19.5014 7.4395L18.8344 8.1015L15.9999 5H10.9999L6.76741 9.6315C6.18141 10.293 6.20741 11.2605 6.86941 11.8465L7.16941 12.1115L10.9999 8.0165V19.3365L9.31991 21.5815C8.81291 22.305 8.94841 23.2635 9.67241 23.7705L9.99991 24L12.9999 20V15H13.9999V24H15.9999V8.017L18.7639 10.971L19.5269 10.208L21.9959 7.739ZM6.21991 22.72C6.14622 22.7887 6.08712 22.8715 6.04613 22.9635C6.00514 23.0555 5.98309 23.1548 5.98132 23.2555C5.97954 23.3562 5.99807 23.4562 6.03579 23.5496C6.07351 23.643 6.12965 23.7278 6.20087 23.799C6.27209 23.8703 6.35692 23.9264 6.45031 23.9641C6.5437 24.0018 6.64373 24.0204 6.74443 24.0186C6.84513 24.0168 6.94445 23.9948 7.03645 23.9538C7.12845 23.9128 7.21125 23.8537 7.27991 23.78C7.41239 23.6378 7.48451 23.4498 7.48108 23.2555C7.47766 23.0612 7.39894 22.8758 7.26153 22.7384C7.12412 22.601 6.93873 22.5223 6.74443 22.5188C6.55013 22.5154 6.36208 22.5875 6.21991 22.72ZM2.21991 22.72C2.14622 22.7887 2.08712 22.8715 2.04613 22.9635C2.00514 23.0555 1.98309 23.1548 1.98132 23.2555C1.97954 23.3562 1.99807 23.4562 2.03579 23.5496C2.07351 23.643 2.12965 23.7278 2.20087 23.799C2.27209 23.8703 2.35692 23.9264 2.45031 23.9641C2.5437 24.0018 2.64373 24.0204 2.74443 24.0186C2.84513 24.0168 2.94445 23.9948 3.03645 23.9538C3.12845 23.9128 3.21125 23.8537 3.27991 23.78C3.41239 23.6378 3.48451 23.4498 3.48108 23.2555C3.47766 23.0612 3.39894 22.8758 3.26153 22.7384C3.12412 22.601 2.93873 22.5223 2.74443 22.5188C2.55013 22.5154 2.36208 22.5875 2.21991 22.72ZM6.5 13V14V15H7.5V22H2V15H3V13H6.5ZM5.5 14H4V15H5.5V14Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1374">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
})
FootPassengerIcon.displayName = 'FootPassengerIcon'
export default FootPassengerIcon
