import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const MotorcycleSidecarIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_3_1401)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.053 11.74C16.6049 11.5821 17.176 11.5013 17.75 11.5C21.1965 11.5 24 14.3035 24 17.75C24 21.1965 21.1965 24 17.75 24C14.3035 24 11.5 21.1965 11.5 17.75C11.5 15.6045 12.587 13.709 14.239 12.583L13.503 11.005C12.6265 12.1065 11.6915 13.3265 10.7515 14.559C10.0165 12.216 7.718 10.5 5 10.5H0V9H1.5C2.57785 9 3.39721 8.23469 4.29774 7.39356C5.06818 6.67394 5.89803 5.89883 7 5.5C8.38832 4.99774 9.01509 4.99839 10.4028 4.99984C10.4795 4.99992 10.5585 5 10.64 5H10.703L9.071 1.5H6V0H10.578L16.053 11.74ZM13.5 17.75C13.5 20.0935 15.4065 22 17.75 22C20.0935 22 22 20.0935 22 17.75C22 15.4065 20.0935 13.5 17.75 13.5C17.4687 13.5002 17.1882 13.5283 16.9125 13.584L18.6425 17.294C18.7546 17.5344 18.7665 17.8095 18.6757 18.0587C18.585 18.308 18.3989 18.5109 18.1585 18.623C17.9181 18.7351 17.643 18.747 17.3938 18.6562C17.1445 18.5655 16.9416 18.3794 16.8295 18.139L15.1015 14.432C14.6023 14.8291 14.199 15.3336 13.9217 15.9081C13.6445 16.4825 13.5003 17.1121 13.5 17.75ZM17 5V9C16.4696 9 15.9609 8.78929 15.5858 8.41421C15.2107 8.03914 15 7.53043 15 7C15 6.46957 15.2107 5.96086 15.5858 5.58579C15.9609 5.21071 16.4696 5 17 5ZM0 11.5065L5 11.5C7.6475 11.5 10 13.5135 10 16.1615V20H5.287C4.6315 22.305 2.512 24 0 24V22C1.3925 22 2.594 21.18 3.1575 20H0V11.5065Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1401">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
})
MotorcycleSidecarIcon.displayName = 'MotorcycleSidecarIcon'
export default MotorcycleSidecarIcon
