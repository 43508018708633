import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const FerryReturnIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_3_1364)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1695 2.5L22.183 0H24V5H14.6445L15.6345 2.5H21.1695ZM12.965 8.5H9.166L8.1665 6H24V10.9945H22.3C20.1671 10.9945 19.0793 11.477 17.9714 11.9684C17.0853 12.3615 16.1862 12.7603 14.729 12.9225L12.965 8.5ZM5 9.5H12.287L13.6835 13H0V6H3.603L5 9.5ZM2.24 21.25H0V16.5H21.5C21.5 16.5 17.5035 24 17.5 24H14.3335C11.1398 24 9.57263 23.2868 8.03449 22.5867C6.55237 21.9122 5.09725 21.25 2.24 21.25ZM21.5 14H0V15.5H21.5V14Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1364">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
})
FerryReturnIcon.displayName = 'FerryReturnIcon'
export default FerryReturnIcon
