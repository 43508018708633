import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const SearchIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1973 14.5926C12.1366 15.3688 10.8286 15.8271 9.41355 15.8271C5.87144 15.8271 3 12.9556 3 9.41355C3 5.87144 5.87144 3 9.41355 3C12.9556 3 15.8271 5.87144 15.8271 9.41355C15.8271 10.8285 15.3689 12.1365 14.5927 13.1971L21.0001 19.6046L19.6047 21L13.1973 14.5926ZM4.9734 9.41354C4.9734 11.8658 6.96132 13.8537 9.41354 13.8537C11.8658 13.8537 13.8537 11.8658 13.8537 9.41354C13.8537 6.96132 11.8658 4.9734 9.41354 4.9734C6.96132 4.9734 4.9734 6.96132 4.9734 9.41354Z"
        fill="currentColor"
      />
    </svg>
  )
})
SearchIcon.displayName = 'SearchIcon'
export default SearchIcon
