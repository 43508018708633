import { useTranslation } from 'react-i18next'
import { toDateString } from '../../Utils'

interface Props {
  isOneway: boolean
  outboundDate: Date
  homeboundDate?: Date
  isLoading: boolean
  onClick: () => void
  children: React.ReactNode
}

const OverlayFooter: React.FC<Props> = ({ outboundDate, homeboundDate, children, isLoading }) => {
  const { t } = useTranslation()

  return (
    <div
      id="footer-content"
      className="ts-fixed ts-bottom-0 ts-left-0 ts-right-0 ts-z-50 ts-mt-auto ts-flex ts-h-[80px] ts-min-h-[80px] ts-justify-center ts-border-0 ts-border-solid ts-border-t ts-border-gray-300 ts-bg-white ts-px-dfds-s md:ts-relative lg:ts-hidden"
    >
      <div className="ts-flex ts-w-full ts-flex-col ts-justify-center">
        <div className="ts-flex ts-flex-row ts-items-center">{children}</div>
        <div className="ts-flex ts-flex-row ts-items-center ts-justify-center">
          <div className="ts-flex ts-w-1/2 ts-flex-none ts-flex-col ts-items-center ts-justify-center ts-overflow-hidden ts-px-dfds-s">
            <label className="ts-text-cvi-grey-grey700 ts-text-xs"> {t('OUTBOUND')}</label>
            {outboundDate && (
              <div className="ts-font-medium ts-text-cvi-brand-brand900">{isLoading ? '...' : toDateString(outboundDate)}</div>
            )}
            {!outboundDate && <div className="ts-font-medium ts-text-cvi-brand-brand900">{'-'}</div>}
          </div>
          <div className="ts-h-[100%] ts-border-l ts-border-gray-300 ts-border-solid ts-border-0"></div>
          <div className="ts-flex ts-w-1/2 ts-flex-none ts-flex-col ts-items-center ts-justify-center ts-overflow-hidden ts-px-dfds-s">
            <label className="ts-text-cvi-grey-grey700 ts-text-xs">{t('INBOUND')}</label>
            {homeboundDate && (
              <div className={'ts-font-medium ts-text-cvi-brand-brand900'}>{isLoading ? '...' : toDateString(homeboundDate)}</div>
            )}
            {!homeboundDate && <div className="ts-font-medium ts-text-cvi-brand-brand900">{'-'}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverlayFooter
