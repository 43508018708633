export const isMultipleVehclesSupportedWithRoute = (routeCode: string): boolean =>
  !['DVCA', 'DVDK', 'DKDV', 'CADV', 'DPNH', 'NHDP', 'SHSM', 'SMSH', 'SHPO', 'POSH', 'SHPM', 'PMSH'].includes(routeCode)

export const isMultipleVehclesSupportedWithVehicleType = (routeCode: string): boolean => {
  // Vehicles
  if (routeCode === 'NCAR') return false
  return true
}
export const getMultipleVehicleSelectedCount = (count: number) => {
  if (!count) return ''
  if (count < 2) return ''
  if (count > 1) return `${count.toString()} `
}
