import clsx from 'clsx'
import React from 'react'
import { IconProps } from '../Utils'

const YesIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5858 6.58582L20 8.00003L9.65685 18.3137L8.24264 16.8995L18.5858 6.58582ZM5.41421 11.2427L9.65685 15.4853L8.24264 16.8995L4 12.6569L5.41421 11.2427Z"
        fill="currentColor"
      />
    </svg>
  )
})
YesIcon.displayName = 'YesIcon'
export default YesIcon
