import { Button } from '@dfds-pax/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ChevronLeftIcon from '../../Icons/ChevronLeftIcon'

interface Props {
  isMultiOffer: boolean
  onMultiOfferClicked: () => void
}

export const BackToMultiOffer: FC<Props> = ({ isMultiOffer, onMultiOfferClicked }) => {
  const { t } = useTranslation()

  if (!isMultiOffer) return null

  return (
    <div className="ts-flex ts-items-center ts-bg-transparent !ts-text-white ts-flex-none ts-cursor-pointer ts-truncate [&>button]:!ts-outline-none">
      <Button
        data-e2e="multiOfferTab"
        className="travel-search-button ts-bg-transparent"
        onClick={() => onMultiOfferClicked()}
        variation="Text"
      >
        <div className="ts-flex ts-items-center ts-text-white">
          <ChevronLeftIcon size="lg" />
          {t('GENERAL-BACK-TO-OFFERS')}
        </div>
      </Button>
    </div>
  )
}
