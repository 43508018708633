import useSWR from 'swr'
import { fetcher, getBaseApi, getLocaleFromUrlOrQuery, getUrlParts, isPreviewUrl } from '../Utils/common'
import { ProductListSections, TravelSearchConfigResponse } from '../../api/travel-search-config'

const baseApi = getBaseApi()

export const useTravelSearchConfig = (): UseTravelSearchConfigResult => {
  const previewId = isPreviewUrl()
  const parts = getUrlParts()
  const locale = getLocaleFromUrlOrQuery()
  let urlSlug = parts.slice(1).join('/') || '/'
  let isNextApplication = false

  if (window.location.hostname === 'localhost' && window.location.pathname.endsWith('/travel')) {
    const parts = window.location.pathname.split('/')
    urlSlug = parts[2]
    isNextApplication = true
  } else {
    // new unified website if element with id __next exists
    isNextApplication = document.getElementById('__next') !== null
  }

  if (window.location.hostname === 'localhost') isNextApplication = true
  const previewIdString = previewId ? `&previewId=${previewId}` : ''
  const url = `${baseApi}/travel-search-config?locale=${locale}&urlSlug=${urlSlug}${previewIdString}&isNextApplication=${isNextApplication}`

  const { data, isLoading } = useSWR(url, { fetcher })

  const travelSearchConfigData = data as TravelSearchConfigResponse

  return {
    isLoading,
    isMultiOffer: travelSearchConfigData?.isMultiOffer,
    availableMinibooks: travelSearchConfigData?.availableMinibooks,
    optionalOfferTitle: travelSearchConfigData?.optionalOfferTitle,
    route: travelSearchConfigData?.route,
  }
}

export interface UseTravelSearchConfigResult {
  isLoading: boolean
  isMultiOffer: boolean
  availableMinibooks: ProductListSections[] | string
  optionalOfferTitle?: string
  route?: string
}
