import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const CarTrailerIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        d="M24 8.75V7.25H15.6333L11.75 12.2427V18.5H14C14 16.567 15.567 15 17.5 15C19.433 15 21 16.567 21 18.5H24V12H20.5V8.75H24ZM19 12H13.839L16.3667 8.75H19V12Z"
        fill="currentColor"
      />
      <path
        d="M17.5 16C16.1193 16 15 17.1193 15 18.5C15 19.8807 16.1193 21 17.5 21C18.8807 21 20 19.8807 20 18.5C20 17.1193 18.8807 16 17.5 16ZM17.5 19.5C16.9476 19.5 16.5 19.0524 16.5 18.5C16.5 17.9476 16.9476 17.5 17.5 17.5C18.0524 17.5 18.5 17.9476 18.5 18.5C18.5 19.0524 18.0524 19.5 17.5 19.5Z"
        fill="currentColor"
      />
      <path
        d="M0 16V17.5C0.552277 17.5 1 17.9477 1 18.5C1 19.0522 0.552277 19.5 0 19.5V21C1.38071 21 2.5 19.8807 2.5 18.5C2.5 17.1193 1.38071 16 0 16Z"
        fill="currentColor"
      />
      <path d="M8.5 9.88232L2.5 4.5H0V15C1.93298 15 3.5 16.567 3.5 18.5H10.5V17H8.5V9.88232Z" fill="currentColor" />
    </svg>
  )
})
CarTrailerIcon.displayName = 'CarTrailerIcon'
export default CarTrailerIcon
