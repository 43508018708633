import { useTranslation } from 'react-i18next'
import MultiOfferModal from './MultiOfferModal'
import { useWindowWidth } from '../../Hooks'
import { ProductListSections } from '../../../api/travel-search-config'
import { unlockScroll } from '../../Utils/scrolling'
import clsx from 'clsx'
import FerryReturnIcon from '../../Icons/FerryReturnIcon'
import { Button } from '@dfds-pax/ui'

interface Props {
  offerModalShown: boolean
  route: string
  optionalOfferTitle?: string
  productLists: ProductListSections[]
  inView: boolean
  onProductClicked: (minibookId: string) => void
  onRequestOfferModal: (show: boolean) => void
}

const MultiOffer: React.FC<Props> = ({
  route,
  productLists,
  offerModalShown,
  onProductClicked,
  optionalOfferTitle,
  inView,
  onRequestOfferModal,
}) => {
  const { t } = useTranslation()
  const { widthSmallerThan1025 } = useWindowWidth()

  return (
    <>
      <div className={`ts-flex ts-bg-transparent ${inView ? 'ts-w-full' : 'ts-w-fit'}`}>
        {!widthSmallerThan1025 ? (
          <div className="ts-flex ts-w-full">
            <div className={`ts-z-5000 ts-flex ${inView ? 'ts-w-full ts-bg-transparent' : 'ts-bg-dfds-surface-light/30'}`}>
              <div className={clsx('ts-flex ts-w-full ts-items-center', inView ? 'ts-p-0' : 'ts-p-dfds-xs')}>
                <FerryReturnIcon
                  className={clsx(inView ? 'ts-text-cvi-brand-brand50' : 'ts-text-cvi-brand-brand900', 'ts-h-[24px] ts-w-[24px]')}
                  size="lg"
                />
                <div className="ts-flex ts-ml-dfds-xs ts-flex-col">
                  {optionalOfferTitle ? (
                    <div
                      className={clsx(
                        inView ? 'ts-text-cvi-brand-brand50' : 'ts-text-cvi-brand-brand900 ts-line-clamp-2 ts-max-w-[200px]',
                        'ts-font-medium ts-mr-dfds-s'
                      )}
                    >
                      {optionalOfferTitle}
                    </div>
                  ) : (
                    <div className={clsx(inView ? 'ts-text-cvi-brand-brand50' : 'ts-text-cvi-brand-brand900 ts-mr-dfds-s')}>
                      <div className="ts-font-medium">{t('GENERAL-ROUTE')}</div>
                      <div className="ts-font-medium">{route}</div>
                    </div>
                  )}
                </div>
                <div className="ts-ml-auto dfds-orange-button">
                  <Button
                    variation="CviBlue"
                    className="travel-search-button"
                    data-e2e="multiofferButton"
                    onClick={() => onRequestOfferModal(true)}
                  >
                    {t('SEE-OFFERS')}
                  </Button>
                </div>
              </div>
            </div>
            <div
              className={`ts-border-solid ts-h-0 ts-w-0 ts-border-b-transparent ts-border-t-transparent ${
                inView ? 'ts-border-none' : 'ts-border-l-dfds-surface-light/30'
              }`}
            ></div>
          </div>
        ) : (
          <div data-e2e="multiofferButton" className="ts-font-medium md:ts-right-0 mobile:ts-left-0 dfds-orange-button">
            <Button variation="CviBlue" className="travel-search-button" onClick={() => onRequestOfferModal(true)}>
              {t('SEE-OFFERS')}
            </Button>
          </div>
        )}
      </div>
      <MultiOfferModal
        productLists={productLists}
        show={offerModalShown}
        onClose={() => {
          unlockScroll()
          onRequestOfferModal(false)
        }}
        onProductClicked={(id) => {
          unlockScroll()
          onProductClicked(id)
        }}
      />
    </>
  )
}

export default MultiOffer

export interface ProductList {
  description: string
  products: Product[]
}
export interface Product {
  title: string
  productDetails: string
  offerDetails: string
  minibookId: string
}
