import { motion } from 'framer-motion'
import { ReactNode, useEffect } from 'react'
import ModalHeader from './ModalHeader'
import Overlay from '../Overlay/Overlay'
import { unlockScroll } from '../../../Utils/scrolling'
import { Button } from '@dfds-pax/ui'

export type ModalProps = {
  isOpen: boolean
  closeAble?: boolean
  fullscreenMobile?: boolean
  headline: string
  children: ReactNode
  primaryButtonText?: string
  secondaryButtonText?: string
  noPadding?: boolean
  onPrimaryButtonClicked?: () => void
  onSecondaryButtonClicked?: () => void
  onCloseRequested: () => void
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  closeAble = true,
  fullscreenMobile = false,
  headline,
  children,
  primaryButtonText,
  secondaryButtonText,
  noPadding,
  onPrimaryButtonClicked,
  onSecondaryButtonClicked,
  onCloseRequested,
}) => {
  useEffect(() => {
    document.onkeydown = function (evt: KeyboardEvent) {
      if (evt.code === 'Escape' && closeAble) {
        unlockScroll()
        onCloseRequested()
      }
    }
  }, [])

  return (
    <>
      {isOpen && (
        <Overlay isOpen={isOpen} onCloseRequested={() => closeAble && onCloseRequested()}>
          <motion.div
            id="modal-content-container"
            className={`md:ts-max-w-600px] md:ts-min-h-[200px] md:ts-min-w-[400px] mobile:ts-min-w-[90%] ${
              fullscreenMobile && 'mobile:ts-h-[100%] mobile:ts-w-[100%]'
            } ts-flex ts-flex-col ts-bg-white`}
            initial={{
              opacity: 0,
              y: -20,
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                ease: 'easeOut',
                duration: 0.2,
              },
            }}
          >
            <ModalHeader
              closeAble={closeAble}
              onCloseRequested={() => {
                unlockScroll()
                onCloseRequested()
              }}
            >
              {headline}
            </ModalHeader>
            <div data-e2e="modal-content" className={`ts-flex ts-flex-1 ts-flex-col ts-overflow-y-auto ${!noPadding && 'ts-p-dfds-s'}`}>
              {children}
              {(onPrimaryButtonClicked || onSecondaryButtonClicked) && (
                <div data-e2e="modal-footer" className="ts-bottom-0 ts-left-0 ts-right-0 ts-flex ts-flex-row ts-bg-dfds-surface-light">
                  {onPrimaryButtonClicked && primaryButtonText && (
                    <Button
                      variation="Secondary"
                      className="ts-ml-auto ts-min-w-[120px] travel-search-button"
                      onClick={() => {
                        unlockScroll()
                        onPrimaryButtonClicked()
                      }}
                    >
                      {primaryButtonText}
                    </Button>
                  )}

                  {onSecondaryButtonClicked && secondaryButtonText && (
                    <Button
                      variation="Outlined"
                      className="ts-ml-dfds-xs ts-min-w-[120px] travel-search-button"
                      onClick={() => {
                        unlockScroll()
                        onSecondaryButtonClicked()
                      }}
                    >
                      {secondaryButtonText}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </motion.div>
        </Overlay>
      )}
    </>
  )
}

export default Modal
