import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useTravelSearchContext } from '../../context/TravelSearchProvider'

interface Props {
  onewayDisabled: boolean
}

const OnewayReturn: React.FC<Props> = ({ onewayDisabled }) => {
  const { t } = useTranslation()
  const {
    state: { isOneWay },
    dispatch,
  } = useTravelSearchContext()

  function handleSelected(isOneWay: boolean) {
    dispatch({ type: 'SET_ONEWAY', isOneWay })
  }
  return (
    <div
      id="returnOneway"
      className="ts-cursor-pointer ts-h-[45px] ts-items-center ts-px-dfds-xxs ts-bg-white ts-flex ts-flex-row ts-w-fit ts-border ts-border-solid ts-border-cvi-grey-grey200"
    >
      <button
        id="returnTab"
        data-e2e="returnTab"
        data-e2e-selected={!isOneWay}
        className={clsx(
          'travel-search-button ts-cursor-pointer ts-h-[35px] ts-px-dfds-s ts-text-cvi-brand-brand900 ts-font-medium',
          !isOneWay ? 'ts-bg-cvi-brand-brand100' : 'ts-bg-white'
        )}
        onClick={() => handleSelected(false)}
      >
        {t('RETURN')}
      </button>
      <button
        id="onewayTab"
        disabled={onewayDisabled}
        data-e2e="oneWayTab"
        data-e2e-selected={isOneWay}
        className={clsx(
          'travel-search-button ts-cursor-pointer ts-h-[35px] ts-px-dfds-s ts-text-cvi-brand-brand900 ts-font-medium',
          isOneWay && !onewayDisabled ? 'ts-bg-cvi-brand-brand100' : 'ts-bg-white',
          onewayDisabled && 'ts-text-cvi-grey-grey300 ts-cursor-not-allowed'
        )}
        onClick={() => handleSelected(true)}
      >
        {t('ONE-WAY')}
      </button>
    </div>
  )
}

export default OnewayReturn
