import clsx from 'clsx'
import Skeleton from './Skeleton'

export const TravelSearchSkeleton = () => {
  return (
    <div id="skeletons" className="ts-relative ts-flex ts-flex-col ts-gap-dfds-xs">
      <div className="ts-flex ts-flex-row ts-items-center ts-h-[45px]">
        <Skeleton className="ts-h-full ts-w-[175px] ts-rounded-[4px]" />
      </div>
      <div className="ts-grid ts-grid-cols-12 lg:ts-grid-cols-6 lg:ts-grid-rows-1 lg:ts-gap-dfds-xxs ts-gap-dfds-xs ts ts-bg-transparent">
        <div className="ts-h-[58px] ts-w-full lg:ts-col-auto md:ts-col-span-6 ts-col-span-12 ">
          <Skeleton className="ts-h-full ts-w-full" />
        </div>
        <div className={clsx('ts-h-[58px] ts-w-full ts-justify-center md:ts-col-span-6 ts-col-span-12 lg:ts-col-auto ')}>
          <Skeleton className="ts-h-full ts-w-full" />
        </div>
        <div className={clsx('ts-h-[58px] ts-w-full ts-col-span-12 md:ts-col-span-6 lg:ts-col-auto ')}>
          <Skeleton className="ts-h-full ts-w-full" />
        </div>
        <div className="ts-h-[58px] ts-w-full ts-col-span-12 md:ts-col-span-6 lg:ts-col-span-2">
          <Skeleton className="ts-h-full ts-w-full" />
        </div>
        <div className="ts-col-span-12 lg:ts-col-span-1">
          <Skeleton className="ts-h-[54px] md:ts-h-[58px] ts-w-full" />
        </div>
      </div>
    </div>
  )
}
