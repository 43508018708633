import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const CurrencyIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        d="M0 5V19H24V5H0ZM4 17C4 16.4696 3.78929 15.9609 3.41421 15.5858C3.03914 15.2107 2.53043 15 2 15V9C2.53043 9 3.03914 8.78929 3.41421 8.41421C3.78929 8.03914 4 7.53043 4 7H20C20 7.53043 20.2107 8.03914 20.5858 8.41421C20.9609 8.78929 21.4696 9 22 9V15C21.4696 15 20.9609 15.2107 20.5858 15.5858C20.2107 15.9609 20 16.4696 20 17H4Z"
        fill="currentColor"
      />
      <path
        d="M18.5 13.5C19.3284 13.5 20 12.8284 20 12C20 11.1716 19.3284 10.5 18.5 10.5C17.6716 10.5 17 11.1716 17 12C17 12.8284 17.6716 13.5 18.5 13.5Z"
        fill="currentColor"
      />
      <path
        d="M5.5 13.5C6.32843 13.5 7 12.8284 7 12C7 11.1716 6.32843 10.5 5.5 10.5C4.67157 10.5 4 11.1716 4 12C4 12.8284 4.67157 13.5 5.5 13.5Z"
        fill="currentColor"
      />
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        fill="currentColor"
      />
    </svg>
  )
})
CurrencyIcon.displayName = 'CurrencyIcon'
export default CurrencyIcon
