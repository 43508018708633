import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const CloseIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12L4.99999 18L6.41421 19.4142L12.4142 13.4142L18 19L19.4142 17.5858L13.8284 12L19.1421 6.68628L17.7279 5.27207L12.4142 10.5858L6.6863 4.85786L5.27208 6.27207L11 12Z"
        fill="currentColor"
      />
    </svg>
  )
})
CloseIcon.displayName = 'CloseIcon'
export default CloseIcon
