import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const ChevronLeftIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0795 7.48046L11.0555 11.9835L15.9193 16.6591L14.3727 18.0423L8.00063 11.9301L14.5821 6.04411L16.0795 7.48046Z"
        fill="currentColor"
      />
    </svg>
  )
})
ChevronLeftIcon.displayName = 'ChevronLeftIcon'
export default ChevronLeftIcon
