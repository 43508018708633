import Fuse from 'fuse.js'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TextField } from '@dfds-pax/ui'
import clsx from 'clsx'
import SearchIcon from '../../Icons/SearchIcon'
import { Category, Route } from '../../Hooks/routes/route-types'

interface Props {
  routeCategories: Category[]
  onSelectRoute: (route: Route) => void
  isOpen: boolean
  isScrollable: boolean
}

const RoutesWithSearch: React.FC<Props> = ({ routeCategories, onSelectRoute, isOpen, isScrollable }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCategories, setFilteredCategories] = useState<Category[]>(routeCategories)
  const [maxHeight, setMaxHeight] = useState(1000) // Default height

  const divRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const adjustHeight = () => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      if (isScrollable && rect.bottom >= viewportHeight) {
        setMaxHeight(viewportHeight - rect.top)
      } else {
        setMaxHeight(1000)
      }
    }
  }

  useEffect(() => {
    adjustHeight()
    window.addEventListener('resize', adjustHeight)
    return () => {
      window.removeEventListener('resize', adjustHeight)
    }
  }, [])

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current && isOpen) {
      inputRef.current.focus()
    }
  }, [isOpen])

  useEffect(() => {
    adjustHeight()
  }, [divRef.current?.offsetHeight, divRef.current?.offsetTop, isOpen, isScrollable])

  useEffect(() => {
    setFilteredCategories(routeCategories)
  }, [routeCategories])

  const flatRoutes = useMemo(() => {
    return routeCategories.flatMap((category) =>
      category.routes.map((route) => ({
        routeName: route.name,
        routeCode: route.routeCode,
        categoryName: category.name,
      }))
    )
  }, [routeCategories])

  const fuseCategories = useMemo(
    () =>
      new Fuse(routeCategories, {
        keys: ['name'],
        threshold: 0.4, //Added to allow for partial matches
        distance: 100, //Added to allow for partial matches
      }),
    [routeCategories]
  )

  const fuse = useMemo(
    () =>
      new Fuse(flatRoutes, {
        keys: ['routeName', 'routeCode'],
        threshold: 0.4, //Added to allow for partial matches
        distance: 80, //Added to allow for partial matches
      }),
    [flatRoutes]
  )

  const handleSearch = (e: React.ChangeEvent) => {
    const term = (e.target as HTMLInputElement).value
    setSearchTerm(term)

    if (term.trim() === '') {
      setFilteredCategories(routeCategories)
      return
    }

    const categoryResults = fuseCategories.search(term)
    const results = fuse.search(term)
    const matchingRouteCodes = new Set(results.map((result) => result.item.routeCode))
    const filtered = routeCategories
      .map((category) => {
        const matchedRoutes = category.routes.filter(
          (route) => matchingRouteCodes.has(route.routeCode) || categoryResults.find((catRes) => catRes.item.name === category.name)
        )

        return {
          ...category,
          routes: matchedRoutes,
        }
      })
      .filter((category) => category.routes.length > 0) // Remove categories with no matching routes

    setFilteredCategories(filtered)
  }

  return (
    <div
      ref={divRef}
      className={clsx(
        'ts-bg-dfds-surface-light',
        'lg:ts-w-[calc(100vw_-_144px)] lg:ts-p-dfds-s xl:ts-w-[calc(100vw_-_33px)] xl:ts-max-w-[1137px] ]',
        'ts-overflow-auto ts-flex ts-flex-col ts-gap-3 ts-relative ts-pb-dfds-m',
        'ts-scrollbar-none lg:ts-scrollbar-thin lg:ts-scrollbar-track-transparent  lg:ts-scrollbar-thumb-dfds-text-dark-disabled ts-overscroll-none'
      )}
      style={{ maxHeight: `${window.innerWidth > 1025 && maxHeight}px` }}
    >
      <div className="ts-sticky md:ts-relative ts-top-0 ts-box-border ts-bg-dfds-surface-light ts-pt-dfds-s ts-px-dfds-s ts-w-full md:ts-w-[400px] ">
        <TextField
          id="routeSearch"
          ref={inputRef}
          value={searchTerm}
          onChange={handleSearch}
          inputType="text"
          placeholder="Search for any route"
          icon={<SearchIcon size="md" className="ts-text-dfds-text-dark-primary" />}
          autoFocus
          onBlur={() => {
            setTimeout(() => {
              setSearchTerm('')
              setFilteredCategories(routeCategories)
            }, 200)
          }}
        />
      </div>
      <ul className="ts-border-0 ts-border-solid ts-p-0 ts-m-0 lg:ts-columns-4 mobile:ts-columns-1 tablet:ts-columns-3">
        {filteredCategories &&
          filteredCategories.map((routeCategory) => (
            <div key={routeCategory?.name} data-e2e="route-category" className="ts-break-inside-avoid">
              <div className="ts-flex ts-h-[70px]  ts-items-center ts-border-b ts-border-0 ts-border-solid ts-border-dfds-divider-dark ts-border-b-dfds-divider-dark ts-pl-dfds-s md:ts-border-none lg:ts-h-[50px]">
                <label className="ts-mt-dfds-s ts-text-cvi-brand-brand900 ts-text-base ts-font-medium lg:ts-mb-0 lg:ts-ml-0">
                  {routeCategory?.name}
                </label>
              </div>
              <ul className="ts-p-0 ts-m-0">
                {routeCategory.routes.map((route) => (
                  <li
                    onClick={() => onSelectRoute(route)}
                    className="ts-border-0 ts-border-solid ts-m-0 ts-list-none ts-p-0 ts-mb-0 ts-ml-0 ts-flex ts-h-[50px] ts-cursor-pointer ts-items-center ts-border-b-[1px] ts-border-dfds-divider-dark ts-pl-dfds-s md:ts-border-none lg:ts-h-[40px]"
                    key={route.routeCode}
                  >
                    <div data-e2e={route.routeCode} data-e2e-name={'route-names'}>
                      <span className="ts-text-cvi-brand-brand500 ts-text-base ts-font-normal hover:ts-text-cvi-brand-brand600">
                        {route.name?.replaceAll('–', ' - ').replaceAll('-', ' - ')}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </ul>
    </div>
  )
}

export default RoutesWithSearch
