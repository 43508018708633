import { useEffect } from 'react'
import { LoadDataFromParams } from '../../Utils/url-helpers'
import { useTravelSearchContext } from '../../context/TravelSearchProvider'
import { useRoutes } from './useRoutes'

export const useLoadRoutesIntoContext = (minibookId?: string) => {
  const { dispatch } = useTravelSearchContext()
  const { queryData } = LoadDataFromParams()
  const { routesApiData } = useRoutes(minibookId)

  useEffect(() => {
    if (!routesApiData) return

    //Take default route or first route in list, unless routeCode is provided in the queryParams and exist in routesApiData
    let route = routesApiData.defaultRoute ?? routesApiData.categories?.[0]?.routes?.[0]

    if (queryData.routeCode) {
      const found = routesApiData.categories.flatMap((c) => c.routes).find((r) => r.routeCode === queryData.routeCode)

      if (found) {
        route = found
      }
    }

    dispatch({ type: 'SET_ROUTE', route })
    dispatch({ type: 'SET_ROUTES_API_DATA', payload: routesApiData })
  }, [routesApiData])
}
