import BicycleIcon from '../../Icons/BicycleIcon'
import BusIcon from '../../Icons/BusIcon'
import CaravanIcon from '../../Icons/CaravanIcon'
import CarIcon from '../../Icons/CarIcon'
import CarRoofBoxIcon from '../../Icons/CarRoofBoxIcon'
import CarTrailerIcon from '../../Icons/CarTrailerIcon'
import FootPassengerIcon from '../../Icons/FootPassengerIcon'
import MotorcycleIcon from '../../Icons/MotorcycleIcon'
import MotorcycleSidecarIcon from '../../Icons/MotorcycleSidecarIcon'
import NoIcon from '../../Icons/NoIcon'

interface Props {
  code: string | undefined
  size?: string
  height?: string
}

export const VehicleIcon: React.FC<Props> = ({ code, size = 'md' }) => {
  if (!code) return null

  const svgProps = { size }
  return (
    <>
      {code === 'NCAR' && <FootPassengerIcon {...svgProps} />}
      {code === 'TWOWHEELS' && <MotorcycleIcon {...svgProps} />}
      {['LARGEVEHICLE', 'MIBUS', 'MINBUS'].includes(code) && <BusIcon {...svgProps} />}
      {code === 'CAR' && <CarIcon {...svgProps} />}
      {code === 'BIKV' && <BicycleIcon {...svgProps} />}
      {code === 'MOC' && <MotorcycleIcon {...svgProps} />}
      {code === 'MOCS' && <MotorcycleSidecarIcon {...svgProps} />}
      {['CARB', 'CARH'].includes(code) && <CarIcon {...svgProps} />}
      {['CARLW', 'CARS'].includes(code) && <CarIcon {...svgProps} />}
      {code === 'CARZ' && <CarRoofBoxIcon {...svgProps} />}
      {['CARX', 'PVANB', 'PVANM'].includes(code) && <CarIcon {...svgProps} />}
      {['MCVB', 'MCVM', 'MCVX', 'MCVS'].includes(code) && <CarIcon {...svgProps} />}
    </>
  )
}

export const TrailerIcon: React.FC<Props> = ({ code, size = 'md' }) => {
  const svgProps = { size }

  if (!code) return <NoIcon {...svgProps} />

  return (
    <>
      {['TRAZ', 'TRAS', 'TRAB', 'TRAX'].includes(code) && <CarTrailerIcon {...svgProps} />}
      {['CAMB', 'CAMS'].includes(code) && <CaravanIcon {...svgProps} />}
    </>
  )
}
