import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const ArrowForwardIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8468 13L12 16.5611L13.367 18L18.8795 13H19V12.8907L20 11.9836L19 11.0599V11H18.9351L13.5223 6L12.1214 7.49814L15.887 11L4 11L4 13L15.8468 13Z"
        fill="currentColor"
      />
    </svg>
  )
})
ArrowForwardIcon.displayName = 'ArrowForwardIcon'
export default ArrowForwardIcon
