import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const MinusIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path d="M5 11H19V13H5V11Z" fill="currentColor" />
    </svg>
  )
})
MinusIcon.displayName = 'MinusIcon'
export default MinusIcon
