import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const NoIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12L6 17L7.41422 18.4142L12.4142 13.4142L17 18L18.4142 16.5858L13.8284 12L18.7279 7.1005L17.3137 5.68629L12.4142 10.5858L7.1005 5.27208L5.68629 6.68629L11 12Z"
        fill="currentColor"
      />
    </svg>
  )
})
NoIcon.displayName = 'NoIcon'
export default NoIcon
