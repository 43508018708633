import React from 'react'
import ReactMarkdown from 'react-markdown'
import clsx from 'clsx'
import rehypeRaw from 'rehype-raw'
import { ExternalLink } from '@dfds-pax/ui'

interface Props {
  input?: string
  className?: string
  showExternalLinkIcon?: boolean
}
const Markdown: React.FC<Props> = ({ input: markdown, className, showExternalLinkIcon }) => {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      className={clsx(
        className,
        'dfds-body-interface ts-text-dfds-text-dark-primary',
        'first-of-type:[&_p]:ts-!m-0',
        '[&_p]:!ts-mt-dfds-xs md:[&_p]:!ts-mt-dfds-s [&_p]:ts-mb-0'
      )}
      components={{
        a(props) {
          const { node, ...rest } = props
          const phoneRegex = /^([+\d].*)?\d$/g
          const isPhoneNumber = props.children?.toString()?.match(phoneRegex) !== null
          if (isPhoneNumber)
            return (
              <span className="ts-gap-dfds-xxs">
                <a className="ts-text-dfds-secondary-main dfds-label" href={`tel:${props?.children}`}>
                  {props?.children}
                </a>
              </span>
            )
          return (
            <span className="text-dfds-secondary-main">
              <a className="ts-text-dfds-secondary-main" {...rest} target="_blank" />
              {showExternalLinkIcon && <ExternalLink width={18} height={18} />}
            </span>
          )
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
}

export default Markdown
