import React from 'react'
import { IconProps } from '../Utils/common'
import clsx from 'clsx'
const CalendarIcon = React.forwardRef<SVGSVGElement, IconProps>(({ size, className, ...props }, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={clsx(className, size === 'md' && 'ts-w-[16px] ts-h-[16px]', size === 'lg' && 'ts-w-[24px] ts-h-[24px]')}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3C17 2.44772 17.4477 2 18 2C18.5523 2 19 2.44772 19 3V5C20.1046 5 21 5.89543 21 7V7.5V9V22.5H3V9V7.5V7C3 5.89543 3.89543 5 5 5H6V3C6 2.44772 6.44772 2 7 2C7.55228 2 8 2.44772 8 3V5H17V3ZM4.5 9H19.5V21H4.5V9ZM16 17H14V19H16V17ZM11 17H13V19H11V17ZM10 17H8V19H10V17ZM14 14H16V16H14V14ZM13 14H11V16H13V14ZM8 14H10V16H8V14ZM16 11H14V13H16V11ZM11 11H13V13H11V11ZM10 11H8V13H10V11Z"
        fill="currentColor"
      />
    </svg>
  )
})
CalendarIcon.displayName = 'CalendarIcon'
export default CalendarIcon
