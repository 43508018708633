export function getUnlocodeFromRouteCode(routeCode: string): string | undefined {
  const unloCode = tempRouteMap.find((x) => x.seabook === routeCode?.toUpperCase())?.route
  return unloCode ?? undefined
}

const tempRouteMap = [
  { seabook: 'CADV', route: 'FRCQF-GBDVR' },
  { seabook: 'DKDV', route: 'FRDKK-GBDVR' },
  { seabook: 'DVCA', route: 'GBDVR-FRCQF' },
  { seabook: 'DVDK', route: 'GBDVR-FRDKK' },
  { seabook: 'KPPA', route: 'SEKPS-EEPLA' },
  { seabook: 'PAKP', route: 'EEPLA-SEKPS' },
  { seabook: 'ANNE', route: 'NLIJM-GBTYN' },
  { seabook: 'ANOV', route: 'NLIJM-NLAMS' },
  { seabook: 'CHDA', route: 'DKCPH-DKCPH' },
  { seabook: 'CHOS', route: 'DKCPH-NOOSL' },
  { seabook: 'COAR', route: 'DKCPH-DKAAR' },
  { seabook: 'COCO', route: 'DKCPH-DKCPH' },
  { seabook: 'DPNH', route: 'FRDPE-GBNHV' },
  { seabook: 'FHOS', route: 'DKFDH-NOOSL' },
  { seabook: 'HKPA', route: 'FIHKO-EEPLA' },
  { seabook: 'KHKL', route: 'SEKAN-LTKLJ' },
  { seabook: 'KIKL', route: 'DEKEL-LTKLJ' },
  { seabook: 'KLKH', route: 'LTKLJ-SEKAN' },
  { seabook: 'KLKI', route: 'LTKLJ-DEKEL' },
  { seabook: 'NEAN', route: 'GBTYN-NLIJM' },
  { seabook: 'NHDP', route: 'GBNHV-FRDPE' },
  { seabook: 'OSCH', route: 'NOOSL-DKCPH' },
  { seabook: 'OSFH', route: 'NOOSL-DKFDH' },
  { seabook: 'PAHK', route: 'EEPLA-FIHKO' },
  { seabook: 'DKRO', route: 'FRDKK-IEROE' },
  { seabook: 'RODK', route: 'IEROE-FRDKK' },
  { seabook: 'SHSM', route: 'JESTH-FRSML' },
  { seabook: 'SMSH', route: 'FRSML-JESTH' },
  { seabook: 'SHPO', route: 'JESTH-GBPOO' },
  { seabook: 'POSH', route: 'GBPOO-JESTH' },
  { seabook: 'SHPM', route: 'JESTH-GBPME' },
  { seabook: 'PMSH', route: 'GBPME-JESTH' },
]
