export const getPassengerFerriesPart = (): string | undefined => {
  const parts = window.location.pathname.split('/')
  return parts.length > 2 ? parts[2] : undefined
}

export type IconProps = {
  size: string
  className?: string
}

export const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms))

export const clearSession = (sessionsToKeep: string[]) => {
  const keep = sessionsToKeep || ['']

  Array(sessionStorage.length)
    .fill(null)
    .map((_, i) => sessionStorage.key(i))
    .filter((e: any) => !keep.includes(e))
    .forEach((e: any) => sessionStorage.removeItem(e))
}

export const fetcher = async (resource: any, init: any) => {
  const res = await fetch(resource, init)
  if (!res.ok) {
    const error = new CustomError(res.statusText ?? 'No status from backend')
    // Attach extra info to the error object.
    const { correlationId, status } = await res.json()
    error.correlationId = correlationId
    error.status = status
    throw error
  }
  return res.json()
}

export class CustomError extends Error {
  constructor(m: string) {
    super(m)
    Object.setPrototypeOf(this, CustomError.prototype)
  }
  correlationId: string | undefined
  status: number | undefined
}

export const getBaseApi = () => {
  let result = `${window.location.origin}/api`
  if (window.location.host === 'www.dfds.com') result = 'https://travel-search-prod.dfds-pax-web.com/api'
  else if (window.location.host === 'www.dev.dfds.com') result = 'https://travel-search-dev.dfds-pax-web.com/api'
  else if (window.location.host === 'booking-experience-dev.dfds-pax-web.com') result = 'https://travel-search-dev.dfds-pax-web.com/api'
  // paxob runs on 5001 locally and unified runs on 3000 which both needs to load travel-search for travel step
  else if (window.location.host !== 'localhost:3001' && window.location.hostname === 'localhost')
    result = 'https://travel-search-dev.dfds-pax-web.com/api'
  else if (window.location.host === 'www.test.dfds.com') result = 'https://travel-search-test.dfds-pax-web.com/api'
  else if (window.location.host === 'booking-experience-test.dfds-pax-web.com') result = 'https://travel-search-test.dfds-pax-web.com/api'
  else if (window.location.host === 'www.accept.dfds.com') result = 'https://travel-search-uat.dfds-pax-web.com/api'
  else if (window.location.host.includes('booking-experience-uat')) result = 'https://travel-search-uat.dfds-pax-web.com/api'
  else if (window.location.host === 'localhost:3001') {
    result = `${window.location.origin}/api` //use local api when running locally
    //result = 'https://travel-search-dev.dfds-pax-web.com/api'
  } else if (window.location.host.includes('dotcom-pr.dfds')) result = 'https://travel-search-dev.dfds-pax-web.com/api'
  else if (window.location.host.includes('dfds-gatsby')) result = 'https://travel-search-dev.dfds-pax-web.com/api'
  return result
}

export const getPathParts = (path: string) => path.split('/').filter((part) => part !== '')

export const getLocaleFromUrlOrQuery = (): string => {
  const parts = getUrlParts()
  const locale = parts[0]
  if (!locale) {
    console.warn('Locale is not defined - defaulting to en')
    return 'en'
  }
  return locale
}

export const getUrlParts = (): string[] => {
  const currentPath = window.location.pathname
  const incomingUrl = new URL(window.location.href)
  const searchConfig = incomingUrl.searchParams.get('searchConfig')
  const parts = searchConfig ? getPathParts(searchConfig) : getPathParts(currentPath)
  return parts
}

export const isPreviewUrl = (): string | undefined => {
  const incomingUrl = new URL(window.location.href)
  const previewId = incomingUrl.pathname.includes('preview') ? incomingUrl.searchParams.get('id') : undefined
  return previewId ?? undefined
}

export const getSessionId = () => {
  let sessionId: string | null = sessionStorage.getItem('travel-search-session-id')
  if (!sessionId) {
    const _sessionId = crypto.randomUUID()
    sessionStorage.setItem('travel-search-session-id', _sessionId)
    sessionId = _sessionId
  }
  return sessionId
}

export function isTravelSearchApp(host: string) {
  return host === 'app-travel-search-prod.dfds-pax-web.com' || host === 'app-travel-search-nonprod.dfds-pax-web.com'
}

export function getAppMinibookId(host: string) {
  if (isTravelSearchApp(host)) {
    return '5NZILXtA97qjAt9YYW9dLn'
  } else return undefined
}
